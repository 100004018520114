import React from "react";
import terms from "../../assets/img/services/terms.svg";
export default function Termsandcontion() {
  return (
    <>
      <section className="inner-page">
        <div className="inner-page-banner d-flex align-items-center justify-content-between">
          <img src={terms} alt="" title="" />
        </div>
      </section>
      <section className="inner-page-content">
        <div className="box">
          <div className="container">
            <h2 className="mb-lg-3 mt-lg-3">GENERAL TERMS AND CONDITIONS</h2>
            <ol>
              <li>
                The minimum age of acceptance of the Terms and Conditions
                contained herewith in this document is 18 years of age.{" "}
              </li>
              <li>
                As a customer, you must possess the authority to accept the
                Terms and Conditions on behalf of a company.{" "}
              </li>
              <li>
                Crowd4Test, is a ‘Company’ as defined by the laws of the nation
                of India. (‘Crowd4Test’) offers an online service whereby
                Customers (‘Customer, Customers’) who are the makers of
                mobile/web/desktop Applications can have these services
                (‘Applications’) tested by people (‘Testers) who have registered
                with the company (Crowd4Test) for use of the said service.{" "}
              </li>
              <li>
                Password and account details must at all times be kept
                confidential and must not be shared with anyone. You as a tester
                are held responsible for all activities and orders that take
                place on the account or get submitted under its password. If you
                suspect that your account has been compromised; or that someone
                else has knowledge of your password, you should notify
                Crowd4Test immediately. Any loss resulting from failure to
                comply with the security obligation will not be the liability of
                Crowd4Test.{" "}
              </li>
              <li>
                Service in this instance is a term that will apply to the online
                testing service, provided by Crowd4Test, which enables Customers
                to have their Applications tested for bugs or flaws by Tester or
                Testers.{" "}
              </li>
              <li>
                Territory or territories are implied and mean all countries of
                the world.{" "}
              </li>
              <li>
                A tester’s services from this point forth will be referred to as
                ‘the Testing services’.{" "}
              </li>
              <li>
                The use of any product or service cannot be advocated,
                advertised or promoted when using the Service and/or the
                Crowd4Test Website.{" "}
              </li>
              <li>
                You must refrain from collecting any third party content or
                information or otherwise access the Service and/or the
                Crowd4Test Website, using automated means (such as bots, robots,
                spiders, or scrapers);{" "}
              </li>
              <li>
                We have a strict policy against SPAM and distribution or posting
                of any material viewed as spam such as unsolicited, or bulk
                electronic communications, chain letters, or pyramid schemes
                would be dealt with as deemed fit by the company{" "}
              </li>
              <li>
                Any attempt at disrupting the service or introducing viruses,
                trojan horses, spyware, cancel bots or other malicious code into
                the Service and/or the Crowd4Test Website will also be dealt
                with as a threat to the Company and dealt with to the fullest
                extent of the law.{" "}
              </li>
              <li>
                You will have to respect the privacy of both the company and its
                clients. With this in mind, you are not to access, tamper with,
                or use non-public areas of the Service and/or the Crowd4Test
                Website, the computer systems of Crowd4Test, or the technical
                delivery systems of its providers.{" "}
              </li>
              <li>
                Probing, scanning and testing the vulnerability of any system or
                network is strictly prohibited. As is the breaching or
                circumventing of any security or authentication measures.{" "}
              </li>
              <li>
                Forging any TCP/IP packet header or any part of the header
                information in any email or posting is strictly and expressly
                forbidden. Sending out altered deceptive or false source
                identifying information using the Service and/or the Crowd4Test
                website is likewise expressly forbidden.{" "}
              </li>
              <li>
                Bullying, intimidation and harassment of any kind to a third
                party is unacceptable and will not be tolerated.{" "}
              </li>
              <li>
                Any illegal, unlawful, misleading, malicious or discriminatory
                activities using the Service or the Crowd4Test Website are
                strictly prohibited.{" "}
              </li>
              <li>
                Any practice that would disable, overburden, or impair the
                proper working of the Service will not be tolerated.{" "}
              </li>
              <li>
                If there ever exists a confusion, ambiguity or any sort of
                discrepancy between the provisions mentioned in this agreement
                and any of the information contained in the pages of the
                Crowd4Test website; the provisions of this agreement will take
                precedence.{" "}
              </li>
              <li>
                The Service and the Company Crowd4Test website will always be
                provided in an ‘AS IS’ condition, this means that Crowd4Test
                expressly disclaims any and all warranties, whether actual or
                assumed, including but not limited to, warranties of
                merchantability, fitness for a particular purpose, title,
                non-infringement (non-interference) and any and all warranties
                arising from the course of dealing and usage of the trade, that
                the Service will meet your requirements, that the Service will
                be always available, accessible, uninterrupted, be free of human
                errors (from time to time), timely, secure, or operate without
                occasional glitches, or errors as to the results obtained as
                ‘output’ from the operation, use or other exploitation of the
                Service and as to the accuracy, infallibility or reliability of
                any information obtained from the Service or the Crowd4Test
                website.{" "}
              </li>
              <li>
                This agreement shall be governed by, construed in and
                interpreted using the laws of the sovereign nation of India.
                Disputes arising out of, in connection to or as a consequence of
                this agreement, shall be settled by the district court of
                Bengaluru, India as first instance, as it will have jurisdiction
                in all matters.{" "}
              </li>
              <li>
                Crowd4Test reserves the right to transfer its rights and
                obligations that may occur as a result of this Agreement, in
                whole, or any part thereof, to any third party without your
                prior written consent.{" "}
              </li>
              <li>
                The ‘Term’ of this ‘Agreement’ shall take effect at the date
                hereof and shall be held valid or in force until terminated by
                any of the parties. This is of course given that the other
                party’s notice of termination has been submitted no less than 30
                days in advance. Notwithstanding the foregoing, in the case that
                the Company, Crowd4Test terminates this agreement before the
                expiry of an on-going testing project cycle; the termination
                shall not be effective, until the obligations of the parties in
                connection with the on-going testing project, i.e. in this case
                the Tester have been fulfilled.{" "}
              </li>
              <li>
                If any proviso of this agreement or its application be declared
                null and void, or unenforceable for any reason whatsoever; in
                whole or in part for any reason whatsoever, the parties involved
                shall amend this agreement in order to give effect, insofar as
                possible that the intent of this agreement. If for whatever
                reason the parties fail to amend this agreement, the provision
                that has been deemed void, invalid or unenforceable, shall be
                deleted and the remaining provisos (agreed upon in intent and
                purpose by both parties) will continue with full force and
                effect.{" "}
              </li>
              <li>
                The execution of this contract is in the digital form and we
                (Crowd4Test) will store is copy in such form, properly signed.{" "}
              </li>
            </ol>

            <h2 className="mb-lg-3 mt-lg-3">
              TERMS AND CONDITIONS BETWEEN CUSTOMER AND CROWD4TEST
            </h2>
            <ol class="terms">
              <li>
                These ‘Terms and Conditions’, including any and all information
                on the Crowd4Test website constitutes ‘This Agreement’, and
                governs your use of the Testing Service as well as your legal
                relationship with Crowd4Test. You enter into this agreement of
                your own free will and in your capacity as a ‘Customer’; which
                implies that you are a developer or producer of Applications, or
                in your capacity as the holder to rights to said Applications.
                You will hereinafter be referred to as ‘Customer’.{" "}
              </li>
              <li>
                In accordance with the terms and conditions expounded in and set
                out in this Agreement, Crowd4Test will make the service
                available to Customers and Testers in the Territory. This
                Agreement also grants Customer(s) a non-exclusive,
                non-transferable and non-assignable license to use the Service,
                in accordance to the provisos of this agreement.{" "}
              </li>
              <li>
                When subscribing to, availing of, or using the Service, the
                Customer may, in regard to each testing service project, decide
                upon the length/duration of the testing project cycle (a testing
                project cycle by definition, however cannot be shorter than 3 or
                longer than 21 days), as well as the fee to be paid in lieu of
                or in consideration of the Testing Services (defined as ‘The
                Testing Fee’). All Testers are selected by Crowd4Test.
                Crowd4Test however does not guarantee the participation of a
                ‘minimum number’ of Testers or the quality and credentials of
                any particular, individual Tester.{" "}
              </li>
              <li>
                Unless specified previously or expressly provided, Crowd4Test
                makes no representations or warranties for the quality of the
                Testing Services provided by the Testers. No guarantees are
                given that any or all of the Test Artifacts (including, but not
                limited to bugs, test cases, test scripts or any other artifacts
                implemented) in connection with an Application will be found,
                diagnosed or resolved as a result of engaging, hiring or working
                with Testers through the Service.{" "}
              </li>
              <li>
                The mechanism of ‘delivery’ applied will be as follows. The
                Testers will deliver the results of the test to Crowd4Test
                within the stipulated expiry of the testing project. Thereafter
                within 24 additional hours, Crowd4Test will make the same,
                including the Test Artifacts available to the Customer online,
                whereby the Test Artifacts will be deemed as delivered to the
                Customer.{" "}
              </li>
              <li>
                In case of the satisfactory completion of the Testing Services,
                i.e. if the Testing Services are performed in a manner that the
                results are in conformity with the testing model stipulated in
                the project description, Crowd4Test and the Testers shall have
                deemed to have fulfilled their obligations in regards with the
                Testing Services. If there are any deviations or shortcomings in
                relation to the testing model or methodology, the Testers will
                be given the opportunity to remedy them. For the above proviso
                to hold force however, the Customer will have to notify
                Crowd4Test within a stipulated period of 10 days following their
                receipt of the Testing Artifacts. Failure to comply with this
                proviso will result in Crow4Test and the Testers being deemed as
                having fulfilled their contractual obligations applicable to the
                Testing Services, regardless of the deviations or shortcomings.{" "}
              </li>
              <li>
                In the event of the Customer(s) notifying Crowd4Test of any
                shortcomings or deviations in a timely manner i.e. within the
                stipulated 10 day period, Crowd4Test will remedy, or procure the
                remedy of, such deviations or shortcomings. When remedied,
                Crowd4Test and the Testers will be deemed to have fulfilled
                their obligations with regards to the applicable Testing
                Services. If however, the shortcomings or deviations are not
                remedied within 10 days, Crow4Test and the Testers will be
                deemed to have not fulfilled their obligations with regards to
                the applicable Testing Services or with regards to the
                individual test parameters which are incorrect. In such cases,
                Customers will have no obligation to pay the agreed Testing Fee
                and Service Fee (see below) as regards the tests which are
                incorrect. With respect to the legal relationship (read right of
                redressal), the non-payment of Service Fee will be the only
                remedy (read compensation/financial liability) of such
                deviations or shortcomings.{" "}
              </li>
              <li>
                Crowd4Test functions as nothing more than an intermediary or
                connection point between Customers and Testers. The Customer
                engages the Testers (in its own name and in its own behalf) with
                all matters pertaining to the services provided by the Testers
                in connection to the Testing Services, as such each test shall
                be governed by the following set of conditions (read Terms and
                Conditions between Customer and Testers). For the delineation of
                responsibility and avoidance of any ambiguity, the contracting
                parties with regards to the Testing Services are the Customers
                (Producers of the Application) and the Testers (Testing Agents).{" "}
              </li>
              <li>
                Crowd4Test and the Customer(s) are to remain independent and
                distinct from one another. Neither party, shall have any
                authority whatsoever to enter into agreements or other accept
                any obligations, liabilities or responsibility on behalf of the
                other party, unless expressly agreed to (by both the concerned
                parties) in writing and independently in each individual case.
                Nothing contained in this Agreement, shall be construed as or is
                tantamount to constituency of an agency, of employment, company
                or joint venture of any kind between both parties.{" "}
              </li>
              <li>
                Except in relation to Testing Services, the Customer is
                expressly forbidden from making any kind of contact (written or
                oral communication , direct contact)or engaging in any
                transaction commercial or otherwise, with the individual Tester
                or Testers, whether through the Service and/or the Crowd4Test
                website or otherwise. The Customer(s) agree to withhold contact
                information ( i.e. refrain from posting their email address,
                phone number or any other mode of contact) outside of the
                Crowd4Test website or giving any of the aforementioned
                information to the Testers.{" "}
              </li>
              <li>
                Crowd4Test will render a detailed account of the Testing Fee to
                the Testers, through which payment to each and every Tester,
                entitled to a Testing Fee will be made upon an express request
                by the Tester if and when all the Testing Fees due to the
                individual Tester exceeds US$ 50 (fifty).{" "}
              </li>
              <li>
                Crowd4Test will keep all Testing Fees paid in an Escrow Account,
                independent of its own funds and may only handle and use these
                funds in accordance with the provisions of this agreement.{" "}
              </li>
              <li>
                It is the Customer(s’) responsibility to compensate Crowd4Test,
                for any and all damages suffered by Crowd4Test arising out of,
                or resulting from any breach by the Customer of a provision of
                this agreement. These damages or reparations shall be payable
                with or without proof of or intent of negligence. In addition to
                this right to receive reparations/damages, Crowd4Test reserves
                the right, (shall be entitled to) terminate this agreement with
                immediate effect, or suspend Customer(s’) access to the Service
                in the case of any breach/negligence on the Customer(s’) part of
                the provisions of this agreement. In such a case, if Crowd4Test
                decides to terminate this Agreement or suspend Customer(s’)
                access to the Service for any reasons stated or set out in this
                sub-clause, Crowd4Test will not have any liability (will be
                excused of any responsibility) to the Customer.{" "}
              </li>
              <li>
                All right, intent, title and intellectual property, in interest
                of and pertaining to the Service remains in entirety the
                exclusive property of Crowd4Test. This Agreement in no part
                entitles the Customer to use such materials other than as
                provided for in hereof, nor shall this Agreement bestow upon the
                Customer the right to use Crowd4Test’s name or any of
                Crowd4Test’s trademarks, copyrighted material, logos, domain
                names or other distinctive and proprietary brand features.{" "}
              </li>
              <li>
                Unless specified in writing or agreed to in principle by
                Crowd4Test, Crowd4Test may display Customer(s’) company name,
                logo and public description on the Crowd4Test website or in
                other marketing materials or paraphernalia.{" "}
              </li>
              <li>
                By submitting content to the Service and/or the Crowd4Test
                website (including but not limited to, information submitted
                while creating your account, posting a profile, posting the
                Application(s), posting a testing project or sending messages
                through or to the Crowd4Test website), the Customer(s) hereby
                grant Crowd4Test a universal, wordwide, perpetual, irrevocable,
                royalty-free license to copy and use such content in the Service
                and on the Crowd4Test website for the purpose of this agreement.{" "}
              </li>
              <li>
                Crowd4Test reserves the exclusive right to add services to the
                Crowd4Test website and/or to modify/change/delete or in any way
                alter the nature of such services without prior notice to the
                Customer(s).{" "}
              </li>
              <li>
                Crowd4Test reserves the sole right to make amendments/
                addendums/caveats to this Agreement at any time. The Customer(s)
                will be notified by Crowd4Test of its intent to make such
                amendments/addendums/caveats to this Agreement, by e-mail or
                when the Customer(s) next accesses its/their account. If the
                Customer(s) should notify Crowd4Test that it/they cannot or will
                not accept such amendments/addendums/caveats, Crowd4Test
                reserves the right to/will be entitled to terminate this
                Agreement, either with immediate effect or as per Crowd4Test’s
                discretion. Else the Customer(s’) use of the Service will be
                subjected to the amended agreement.{" "}
              </li>
            </ol>

            <h2 className="mb-lg-3 mt-lg-3">
              TERMS AND CONDITIONS BETWEEN CUSTOMER AND TESTER
            </h2>
            <ol class="terms">
              <li>
                You enter into this agreement in one of two capacities, either
                as a Customer; defined as a producer of Applications, or as a
                Tester; defined as Tester of said Applications. If you enter
                into this agreement as a ‘Customer’ you will hereinafter be
                referred to as a Customer, and if you enter this agreement as a
                ‘Tester’ you will hereinafter be referred to as ‘Tester’.{" "}
              </li>
              <li>
                With regards to each ‘Application Testing’ project that is
                offered by the Customer and accepted by an individual Tester,
                the Customer engages the Tester(s) (in their own name and
                behalf) to perform the Testing Services stipulated in the
                Project Description (‘Testing Services’). Each and every
                independent testing project will be governed by these terms and
                conditions.
              </li>
              <li>
                Let it be understood, that a Tester will be an independent
                contractor to and not an employee of the Customer. Nothing
                mentioned in this document, implicitly or explicitly shall
                constitute or be deemed to constitute an agency, employment,
                company, or joint venture of any sort between both the parties.{" "}
              </li>
              <li>
                It will be considered the Tester’s responsibility to manage,
                plan details and performance of the Testing Services. The Tester
                also assumes responsibility of providing and maintaining all
                necessary computer equipment and internet connectivity,
                necessary for the performance of the Testing Services. It is in
                no way the Customer’s prerogative, responsibility or obligation
                to provide any training, technical and/or administrative support
                or any other assistance in connection with the Tester’s duties
                hereunder.{" "}
              </li>
              <li>
                In the interest of ethics and fair play it is the Tester’s moral
                obligation to certify that he does not work for or is in any way
                affiliated with any competitor of the Customer.{" "}
              </li>
              <li>
                The only use of an Application by a Tester is for the sole
                purpose of testing and reporting bugs and other Test Artifacts
                in connection with the Testing Services. The Tester must abide
                by this proviso and must represent that he/she shall not, under
                any circumstances, copy, or attempt to copy by any means,
                reverse engineer, take screenshots, video captures of, or
                otherwise store the Applications (unless such copies are
                mandated or are made in connection with the downloading of the
                Applications in connection with the Testing Services). The
                Tester agrees non-disclosure of any Applications to other
                Testers, or any third parties whether in person or through any
                digital media including and not limited to, emails, blogs, news
                sources, social networks, or any other form of communication to
                the public. The stipulated terms in the foregoing will also be
                applicable to any concepts, source code, ideas and any other
                conceptual or intellectual property in connection with the
                Applications, regardless of whether such ideas, concepts and
                source code is protected by force of law or not.{" "}
              </li>
              <li>
                The Customer is the owner of the Application and as such
                retains, controls and reserves all rights, titles, interest and
                intellectual property resulting from said Application. This
                right is inclusive and not limited to software, images,
                illustrations, icons, designs, logotypes, fonts, names, concepts
                and all other material contained therein. All copyright,
                trademark, design and patent rights or any other intellectual
                property rights with relation to the Application and all it
                entails are owned by the Customer and protected to the fullest
                extent of the law.{" "}
              </li>
              <li>
                After completion of the Testing Service, the Tester agrees to
                delete all copies of the Application(s) or return the same in
                his possession.{" "}
              </li>
              <li>
                The Tester indemnifies and will hold to no suit the Customer or
                any of its affiliates and business partners (including but not
                limited to, any directors, members, employees and other
                representatives) from and against any and all claims, losses,
                damages, liabilities, costs and expenses, including but not
                limited to, legal expenses and reasonable counsel fees, arising
                out of any breach or alleged breach by Tester of the above
                provisos.{" "}
              </li>
              <li>
                When using the Testing Service, the Tester will have access to
                new and developing Applications and software and information
                about companies that he/she (the Tester) is evaluating the
                Applications and/or softwares for. The Tester hereby
                acknowledges that he/she has an absolute responsibility towards
                maintaining confidentiality of the Applications or software, and
                respect the intellectual right and property of the Customer.
                This intellectual property includes all software, images,
                illustrations, icons, designs, logotypes, names, concepts, ideas
                and all other material contained therein, and all other
                information Tester acquire, learn of, or otherwise have
                awareness of as a result of its use of the Testing Services.{" "}
              </li>
              <li>
                In the interest of ethics and fair play the Tester hereby
                certifies that he or she may not work for, or be affiliated in
                any way to any competitor of the Customer.{" "}
              </li>
              <li>
                The Tester and Customer hereby pledge to compensate each other
                for any and all damages suffered by either party, including any
                losses arising out of breach by either Tester or Customer of a
                provision of this agreement. Damages are payable, with or
                without proof of wilful intent or negligence.{" "}
              </li>
            </ol>
          </div>
        </div>
      </section>
    </>
  );
}

import './App.css';

import { Routes, Route } from 'react-router-dom';

import Header from './components/header/Header';
import Headers from './components/header/Headers';
import Footer from './components/footer/Footer';
import Banner from './components/banner/Banner';
import Home from './components/home/Home';
import Mobile from './components/wetest/Mobile';
import Desktop from './components/wetest/Desktop';
import Website from './components/wetest/Website';
import Arvr from './components/wetest/Arvr';
import Iot from './components/wetest/Iot';
import Security from './components/services/Security';
import PaymentBased from './components/services/PaymentBased';
import Automation from './components/services/Automation';
import API from './components/services/API';
import AI from './components/services/AI';
import Accessibility from './components/services/Accessibility';
import Game from './components/services/Game';
import Compatibility from './components/services/Compatibility';
import Localization from './components/services/Localization';
import Performance from './components/services/Performance';
import Aboutus from './components/about/Aboutus';
import Pricing from './components/pricing/Pricing';
import Faq from './components/faq/Faq';
import Webinar from './components/webinar/Webinar';
import Contact from './components/contact/Contact';
import Tester from './components/auth/Tester';
import Client from './components/auth/Client';
import Forgotpassword from './components/auth/Forgotpassword';
import Termsandcontion from './components/termsAndCondtion/Termsandcontion';
import Privacy from './components/privacypolicy/Privacy';
import Joinus from './components/auth/Joinus';
import Owncrowd from './components/Byoc/Owncrowd';
function App() {
  return (
    <>
      <Headers />

      <Routes>
        <Route exact path="/" element={<><Banner /><Home /></>} />
        <Route path="/Mobile" element={<Mobile />} />
        <Route path="/Desktop" element={<Desktop />} />
        <Route path="/Website" element={<Website />} />
        <Route path="/Arvr" element={<Arvr />} />
        <Route path="/Iot" element={<Iot />} />
        <Route path="/Security" element={<Security />} />
        <Route path="/PaymentBased" element={<PaymentBased />} />
        <Route path="/Automation" element={<Automation />} />
        <Route path="/API" element={<API />} />
        <Route path="/AI" element={<AI />} />
        <Route path="/Accessibility" element={<Accessibility />} />
        <Route path="/Game" element={<Game />} />
        <Route path="/Compatibility" element={<Compatibility />} />
        <Route path="/Localization" element={<Localization />} />
        <Route path="/Performance" element={<Performance />} />
        <Route path="/Aboutus" element={<Aboutus />} />
        <Route path="/Pricing" element={<Pricing />} />
        <Route path="/Faq" element={<Faq />} />
        <Route path="/Webinar" element={<Webinar />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Joinus" element={<Joinus />} />
        <Route path="/Tester" element={<Tester />} />
        <Route path="/Client" element={<Client />} />
        <Route path="/Forgotpassword" element={<Forgotpassword />} />
        <Route path="/Termsandcontion" element={<Termsandcontion />} />
        <Route path="/Privacy" element={<Privacy/>}/>
        <Route path="/Owncrowd" element={<Owncrowd/>}/>

      </Routes>

      <Footer />
    </>
  );
}

export default App;

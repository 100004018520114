import React from 'react'
import bannerOne from '../../assets/img/car3.jpg';
import bannerTwo from '../../assets/img/car2.jpg';
import bannerThree from '../../assets/img/car4.jpg';
import { Swiper, SwiperSlide } from 'swiper/react';
// import required modules
import { Navigation, Autoplay, EffectFade } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';
export default function Banner() {

  return (
    <section id="hero" className="home-banner hero-section-bg">
      <div className="container">
        <div className='col-12'>
            <Swiper
              spaceBetween={40}
              slidesPerView={1}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              navigation={true}
              modules={[Navigation, Autoplay]} 
             
            >
              <SwiperSlide>
              <div className="testimonial-item">
                 <div className="row">
                  <div className="col-12 col-sm-12 col-lg-6 order-lg-1 d-flex flex-column justify-content-center">
                    <h1>
                      Happy Customers 
                    </h1>
                    <h2>
                      for the quality of the product that we deliver across the globe.
                    </h2>
                    <a className="btn btn-outline-primary b-button" href="https://www.crowd4test.com/platform/#/join_us" target="_blank">Join Us</a>
                    
                  </div>
                  <div className="col-12 col-sm-12 col-lg-6 order-lg-1 d-flex flex-column">
                    <img src={bannerOne} alt=""  className="img-fluid"/>
                  </div>
                 </div>
              </div>
              </SwiperSlide>
              <SwiperSlide>
              <div className="testimonial-item">
                 <div className="row">
                  <div className="col-12 col-sm-12 col-lg-6 order-lg-1 d-flex flex-column justify-content-center">
                   <h1>World's foremost organization</h1> 
                    <h2>to deliver transparent, exquisite and cost-efficient testing.</h2>
                    <a className="btn btn-outline-primary b-button" href="https://www.crowd4test.com/platform/#/join_us" target="_blank">Join Us</a>
                  </div>
                  <div className="col-12 col-sm-12 col-lg-6 order-lg-1 d-flex flex-column">
                    <img src={bannerTwo} alt=""  className="img-fluid"/>
                  </div>
                 </div>
              </div>
              </SwiperSlide>
              <SwiperSlide>
              <div className="testimonial-item">
                 <div className="row">
                  <div className="col-12 col-sm-12 col-lg-6 order-lg-1 d-flex flex-column justify-content-center">
                   <h1>Young Experienced</h1> 
                    <h2>and skilled freelance Testers located across the globe</h2>
                    <a className="btn btn-outline-primary b-button" href="https://www.crowd4test.com/platform/#/join_us" target="_blank">Join Us</a>
                  </div>
                  <div className="col-12 col-sm-12 col-lg-6 order-lg-1 d-flex flex-column">
                  <img src={bannerThree} alt=""  className="img-fluid"/>
                  </div>
                 </div>
              </div>
              </SwiperSlide>
             
            </Swiper>
        </div>
      </div>
     </section>
  )
}

import React from "react";
import faqBanner from "../../assets/img/crowd/owncrowd.jpg";
export default function Owncrowd() {
  return (
    <>
      <section className="inner-page">
        <div className="inner-page-banner d-flex align-items-center justify-content-between">
          <img src={faqBanner} alt="" title="" />
        </div>
      </section>
      <section className="inner-page-content">
        <div className="container">
          <div className="section-title">
            <span>Bring-Your-Own-Crowd</span>
            <h2>Bring-Your-Own-Crowd</h2>
          </div>
        </div>
        <div className="box">
          <div className="container">
            <h2 className="mb-lg-3 mt-lg-3">What is Bring-Your-Own-Crowd ?</h2>
            <ul>
              <li>
                <p className="grey-text">
                  Intranet, business website and consumer apps, or expert portals-some digital products require special
                  tester that can't always be found in our Crowd.
                  <br>
                  </br>
                  Bring-Your-Own-Crowd is Our exclusive service for businesses whose software quality assurance
                  requires specific knowledge or contains confidential information, and therefore needs to incorporate 
                  their own end-users or employees in their type of testing.
                  <br>
                  </br>
                  This service allows you to integrate employees, partners, vendors, or even existing customers in the 
                  functional testing and usability testing of your digital product.You also have the chance to combine your 
                  testers with testers from our Crowd.
                </p>
              </li>
            </ul>

            <h2 className="mb-lg-3 mt-lg-3">Turn your employees and clients into testers </h2>
            <ul>
              <li>
                <p className="grey-text">
                  By opting for our Bring-Your-Own-Crowd service, you're able to
                  include your own clients and employees, hence allowing the people
                  who are familiar with your business and product to test your 
                  applications.
                </p>
              </li>
            </ul>

            <h2 className="mb-lg-3 mt-lg-3">
              advantages of Bring-Your-Own-Crowd
            </h2>
            <ul>
              <li>
                <p className="grey-text">
                  Scale your testing for ultimate coverage and improve quality by including employees,partners,
                  vendors, or even existing customers in the test of your digital product.
                </p>
                <li>
                 1.Incorporate employees,partners,
                  or existing customers in your
                  testing process
                </li>
                <li>
                  2.Get support and training for your
                  tester from our experienced project 
                  managers
                </li>
                <li>
                  3.Combine your testers with testers from our Crowd
                </li>
                <li>
                  4.Applicable for various test objects and testing methods
                </li>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
}

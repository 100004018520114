import React, { useState } from "react";
import axios from "axios";
import contact from "../../assets/img/services/contact.svg";
import '../contact/contact.css';
export default function Contact() {
  const [formData, setFormData] = useState([])
  const handleFormData = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value.trim()
    });
  }
  const handleSubmit = (e) => {
    console.log("formData",formData);
    e.preventDefault()       
    axios.post("https://www.crowd4test.com/senddata/sendemail.php", {
      formData
    })
    .then((response) => {
      alert(response.message);   
    });
    
  };
  return (
    <>
 <section className="inner-page">
        <div className="inner-page-banner d-flex align-items-center justify-content-between">
          <img src={contact} alt="" title="" />
        </div>
</section>

    <section id="contact" className="contact">
      <div className="container">

        <div className="section-title">
          <span>Contact</span>
          <h2>Contact</h2>
        
        </div>

        <div className="row">

          <div className="col-lg-5 d-flex align-items-stretch">
            <div className="info">
              <div className="address">
                <i className="bi bi-geo-alt"></i>
                <h4>Location:</h4>
                <p>556, 14th Main,
                  Sector 3, HSR Layout,
                  Bengaluru, Karnataka 560102</p>
              </div>

              <div className="email">
                <i className="bi bi-envelope"></i>
                <h4>Email:</h4>
                <p>admin@crowd4test.com</p>
              </div>

              <div className="phone">
                <i className="bi bi-phone"></i>
                <h4>Call:</h4>
                <p>+91 96323 53367</p>
              </div>
             
            </div>

          </div>

          <div className="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch">
          <div className="row">
              <div className="col-md-12"> 
              <a href="https://www.crowd4test.com/platform/#/join_us" target="_blank" className="getstarted nav-link b-button contact-btn"> Contact us</a>
              </div>
              <div className="col-md-12">
              <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15555.57058849467!2d77.636785!3d12.9146208!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae153fe289056b%3A0x40b123900f48686b!2sCrowd4Test!5e0!3m2!1sen!2sin!4v1690137372307!5m2!1sen!2sin" className="g-map" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
              </div>
            </div>
            {/* <form  onSubmit={handleSubmit} className="php-email-form">
              <div className="row">
                <div className="form-group col-md-6">
                  <label htmlFor="name">Your Name</label>
                  <input type="text" name="name" className="form-control" id="name" required onChange={handleFormData} />
                </div>
                <div className="form-group col-md-6 mt-3 mt-md-0">
                  <label htmlFor="name">Your Email</label>
                  <input type="email" className="form-control" name="email" id="email" required onChange={handleFormData}/>
                </div>
              </div>
              <div className="form-group mt-3">
                <label htmlFor="name">Subject</label>
                <input type="text" className="form-control" name="subject" id="subject"  required onChange={handleFormData}/>
              </div>
              <div className="form-group mt-3">
                <label htmlFor="name">Message</label>
                <textarea className="form-control" name="message" rows="10" required onChange={handleFormData}></textarea>
              </div>
              <div className="my-3">
                <div className="loading">Loading</div>
                <div className="error-message"></div>
                <div className="sent-message">Your message has been sent. Thank you!</div>
              </div>
              <div className="text-center"><button type="submit">Send Message</button></div>
            </form> */}

          </div>

        </div>

      </div>
    </section>
    </>
  );
}

import React from "react";
import services from "../../assets/img/gif/Compatibility.gif";
const rightAlignedHwading = {
  textAlign: "right",
};
export default function Compatibility() {
  return (
    <>
      <section className="inner-page">
        <div className="inner-page-banner d-flex align-items-center justify-content-between">
          <img src={services} alt="" title="" className="rounded-image" />
        </div>
      </section>
      <section className="inner-page-content">
        <div className="container">
          <div className="section-title">
            <span>Compatibility Testing</span>
            <h2>Compatibility Testing</h2>
            <p>
            Compatibility testing is a part of non-functional testing conducted on application software to ensure the application's compatibility with different browsers, database, hardware, operating system, mobile devices, and networks. The application could also impact due to different versions, resolution, internet speed and configuration etc. Hence it’s important to test the application in all possible manners to reduce failures and overcome from embarrassments of bug’s leakage.
            </p>
          </div>
        </div>

        <div className="box">
          <div className="container">
            <h2 className="mb-lg-3 mt-lg-3">Types of Compatibility testing</h2>
            <p>
              <span className="font-weight-bold">
                Backward compatibility Testing
              </span>
              is to verify the behavior of the developed hardware/software 
              with the older versions of the hardware/software.
            </p>

            <p>
              <span className="font-weight-bold">
                Forward compatibility Testing
              </span>
              is to verify the behavior of the developed hardware/software 
              with the older versions of the hardware/software.
            </p>
            <ul>
              <li>
                <p className="teel-text font-weight-bold">Hardware:</p>
                <p className="black-text">
                  It checks software to be compatible with different hardware
                  configurations.
                </p>
              </li>
              <li>
                <p className="teel-text font-weight-bold">Operating Systems:</p>
                <p className="black-text">
                  It checks your software to be compatible with different
                  Operating Systems like Windows, Unix, Mac OS etc.
                </p>
              </li>
              <li>
                <p className="teel-text font-weight-bold">Mobile:</p>
                <p className="black-text">
                  Checking your software is compatible with mobile
                  platforms like Android, iOS etc.
                </p>
              </li>
              <li>
                <p className="teel-text font-weight-bold">Network:</p>
                <p className="black-text">
                  Tests the software's performance under different network 
                  conditions, such as varying speeds or low connectivity.
                </p>
              </li>
              <li>
                <p className="teel-text font-weight-bold">Browser:</p>
                <p className="black-text">
                  valuates how well a website or application performs 
                  across different web browsers.
                </p>
              </li>
              <li>
                <p className="teel-text font-weight-bold">Devices:</p>
                <p className="black-text">
                  It checks compatibility of your software with different
                  devices like USB port Devices, Printers and Scanners, Other
                  media devices and Bluetooth.
                </p>
              </li>
              <li>
                <p className="teel-text font-weight-bold">Software Versions:</p>
                <p className="black-text">
                  It is verifying your software application to be compatible
                  with different versions of the software. For instance checking
                  your Microsoft Word to be compatible with Windows 7, Windows 7
                  SP1, Windows 7 SP2, Windows 7 SP3.
                </p>
              </li>
            </ul>

            <h2 className="mb-lg-3 mt-lg-4">
             Compatibility Testing Process
            </h2>
            <p>
             Compatibility testing involves assessing how well 
             software performs across diverse environments. It
             includes requirement analysis, creating test cases,
             executing tests on varied platforms, reporting issues,
             resolving them, retesting, and documenting results to 
             ensure consistent functionality and user experience
             across browsers, devices, OS versions, and more.
            </p>
          </div>  
        </div>
      </section>
    </>
  );
}

import React from "react";
import services from "../../assets/img/gif/accessibility.gif";
export default function Structured() {
  return (
    <>
      <section className="inner-page">
        <div className="inner-page-banner d-flex align-items-center justify-content-between">
          <img src={services} alt="" title="" />
        </div>
      </section>
      <section className="inner-page-content">
        <div className="container">
          <div className="section-title">
            <span>Accessibility Testing</span>
            <h2>Accessibility Testing</h2>
            <p>
             Accessibility testing ensures that 
             digital content, such as websites 
             and applications, is usable by people
             with disabilities, promoting equal
             access and user experience. 
            </p>
            < br />
            <p>
             Accessibility testing encompasses 
             various types of assessments to 
             ensure digital products are usable
             by people with disabilities.
            </p>

          </div>
        </div>

        <div className="box">
          <div className="container">
            <h2 className="mb-lg-3 mt-lg-3">Some key types include: </h2>
            <ul>
              <li>
                <p className="teel-text font-weight-bold">Manual Testing:</p>
                <p className="black-text">
                 Human testers use assistive technologies like screen readers, voice recognition, and keyboard navigation to evaluate user experience.
                </p>
              </li>
              <li>
                <p className="teel-text font-weight-bold">Automated Testing: </p>
                <p className="black-text">
                 Tools scan code and content to identify accessibility issues automatically, though some issues require manual verification.
                </p>
              </li>
              <li>
                <p className="teel-text font-weight-bold">Functional Testing: </p>
                <p className="black-text">
                 Ensures that all interactive elements, forms, and navigation are operable using assistive technologies.
                </p>
              </li>
              <li>
                <p className="teel-text font-weight-bold">Visual Testing: </p>
                <p className="black-text"> 
                 Checks for proper color contrast, text readability, and effective use of visuals for users with visual impairments.
                </p>
              </li>

              <li>
                <p className="teel-text font-weight-bold">Screen Reader Testing: </p>
                <p className="black-text">
                 Verifies content compatibility with screen readers, which convert text into speech for visually impaired users.
                </p>
              </li>

              <li>
                <p className="teel-text font-weight-bold">Voice Recognition Testing: </p>
                <p className="black-text">
                 Ensures voice-controlled interfaces can be used effectively by individuals with motor disabilities.
                </p>
              </li>

              <li>
                <p className="teel-text font-weight-bold">Cognitive Testing: </p>
                <p className="black-text">
                 Focuses on ease of comprehension, simplicity, and consistent design to aid users with cognitive impairments.
                </p>
              </li>

              <li>
                <p className="teel-text font-weight-bold">Adaptive Technology Testing: </p>
                <p className="black-text">
                 Evaluates compatibility with various assistive tools, such as screen magnifiers, voice input, or switch devices.
                </p>
              </li>

              <li>
                <p className="teel-text font-weight-bold">Mobile Accessibility Testing: </p>
                <p className="black-text">
                 Addresses mobile-specific challenges, ensuring apps are accessible on various devices and platforms.
                </p>
              </li>

              <li>
                <p className="teel-text font-weight-bold">PDF/Document Accessibility Testing: </p>
                <p className="black-text">
                 Checks if documents and PDFs are structured to support screen readers and other assistive tools.
                </p>
              </li>

              <li>
                <p className="teel-text font-weight-bold">Multimedia Accessibility Testing: </p>
                <p className="black-text">
                 Ensures audio and video content includes captions, transcripts, and other accessible features.
                </p>
              </li>

              <li>
                <p className="teel-text font-weight-bold">Form Accessibility Testing: </p>
                <p className="black-text">
                 Verifies forms are properly labeled, easy to navigate, and operable with assistive technologies.
                </p>
              </li>

              <li>
                <p className="teel-text font-weight-bold">Alternative Text Testing: </p>
                <p className="black-text">
                 Checks if all images have appropriate alternative text to convey their content to screen reader users.
                </p>
              </li>

              <li>
                <p className="teel-text font-weight-bold">Focus Management Testing: </p>
                <p className="black-text">
                 Ensures keyboard focus is visible and consistent, aiding users who navigate using keyboards.
                </p>
              </li>
            </ul>
            <br />
            <p>
            This testing aims to identify and rectify barriers that might prevent people with various impairments, such as visual, auditory, motor, or cognitive disabilities, from accessing and interacting with the digital content effectively. By adhering to accessibility standards and guidelines, organizations can provide an inclusive user experience that accommodates a diverse range of users.
            </p>
            <h2 className="mb-lg-3 mt-lg-4">Testing approach for Accessibility :</h2>
            <p className="black-text">
             The accessibility testing approach involves understanding standards, manual and automated evaluations, covering elements like navigation, forms, multimedia, and documentation. Issues are reported, prioritized, and remediated with continuous monitoring for inclusive digital experiences.
            </p>
          </div>
        </div>
      </section>
    </>
  );
}

import React from "react";
import pricingBanne from "../../assets/img/services/pricing_banner.svg";
import { Link } from "react-router-dom";
export default function Pricing() {
  return (
    <>
      <section className="inner-page">
        <div className="inner-page-banner d-flex align-items-center justify-content-between">
          <img src={pricingBanne} alt="" title="" />
        </div>
      </section>
      <section className="inner-page-content">
        <div className="container pricing text-center">
          <div className="row justify-content-center">
            <div className=" py-2 col-sm-4 col-md-3 d-flex flex-column justify-content-between">
              <p className="teel-bg text-white py-4 text-center header">
                Hire a Tester
              </p>
              <p></p>
              <p></p>
              <p className="font-weight-bold">Virtual QA Team</p>
              <p className="font-weight-bold grey-text">
                Hourly
                <br />
                Weekly
                <br />
                Monthly
              </p>
              <p></p>
              <p>
                Hire a pool of freelance testers from Crowd4Test as an
                additional support in existing with your QA Team.
              </p>
              <p></p>
              <Link to="/Joinus">
                <button className="teel-bg text-white border-0 mx-2">
                  Contact Us
                </button>
              </Link>
            </div>
            <div className=" py-2 col-sm-4 col-md-3 d-flex flex-column justify-content-between">
              <p className="teel-bg text-white py-4 text-center header">
                Managed Crowd Testing
              </p>
              <p></p>
              <p className="font-weight-bold">Monthly</p>
              <p>
                For the medium and big enterprises or organization which require
                crowd testing on a recurring basis for every release happen in a
                month.
              </p>
              <p></p>
              <p></p>
              <p className="font-weight-bold">On-demand</p>
              <p>
                For the low and medium enterprises or organization which require
                crowd testing for one time before the launch or any important
                release of the month.
              </p>
              <p></p>
              <Link to="/Joinus">
                <button className="teel-bg text-white border-0 mx-2">
                  Contact Us
                </button>
              </Link>
            </div>
            <div className=" py-2 col-sm-4 col-md-3 d-flex flex-column justify-content-between">
              <p className="teel-bg text-white py-4 text-center header">
                On Premise
              </p>
              <p className="font-weight-bold">Yearly Subscription</p>
              <p>
                For the big enterprises or organizations which requires a highly
                experienced team of testers to serve as a helping hand or an
                extension for the existing internal QA team.
              </p>
              <p className="font-weight-bold">Customized</p>
              <p>
                For the big enterprises or organizations which requires a highly
                experienced team of testers which can be customized according to
                the client need to serve as an extension for the internal QA
                team.
              </p>
              <Link to="/Joinus">
                <button className="teel-bg text-white border-0 mx-2">
                  Contact Us
                </button>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

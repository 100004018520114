import React from "react";

export default function Iot() {
  return (
    <>
      <section className="inner-page">
        <div className="inner-page-banner d-flex align-items-center justify-content-between">
          <img
            src={require("../../assets/img/wetest/iot.png")}
            alt=""
            title=""
          />
        </div>
      </section>
      <section className="inner-page-content">
        <div className="container">
          <div className="section-title">
            <span>IoT Testing</span>
            <h2>IoT Testing</h2>
            <p>
              The Internet of things (IoT) is the extension of Internet
              connectivity into physical devices and everyday objects. Embedded
              with electronics, Internet connectivity, and other forms of
              hardware (such as sensors), these devices can communicate and
              interact with others over the Internet, and they can be remotely
              monitored and controlled.
            </p>
                        
          </div>
        </div>

        <div className="box">
          <div className="container">
          <p className="teel-text">Technologies implemented in IoT</p>
            <ul>
                <li>Radio Frequency Code [RFID]</li>
                <li>Near Field Communication [NFC]</li>
                <li>Bluetooth</li>
                <li>Z-Wave</li>
                <li>Wireless Fidelity [Wifi]</li>
            </ul>

            <h2 className="mb-lg-3 mt-lg-3">
             Major challenges faced in into Testing
            </h2>
            <ul>
                <li>
                    <p className="teel-text font-weight-bold">Hardware and Software</p>
                    <p className="grey-text">IoT is an architecture, which is closely coupled among various hardware and software components. It is not only the software applications that make the system but also the hardware ones, sensors, communication gateways, etc. to play a vital role. So, it becomes a tedious job as compared to testing a generic system [only software/ hardware component].</p>
                </li>
                <li>
                    <p className="teel-text font-weight-bold">Device Interaction Module</p>
                    <p className="grey-text">This is an architecture between different set(s) of hardware and software, it is mandatory that they talk with each other in real time. When both integrate with each other, things such as backward compatibility, security, upgrade issues becomes a challenge for the testing team.</p>
                </li>
                <li>
                    <p className="teel-text font-weight-bold">UI</p>
                    <p className="grey-text">The IoT is pre-owned across devices belonging to every platform [iOS, Android, Windows, linux]. Now, testing that out on devices can be done but testing it on all possible devices is almost impossible. We cannot omit the possibility of the UI being accessed from a device which we don’t possess or simulate. That’s a challenge which is tough to overcome.</p>
                </li>
                <li>
                    <p className="teel-text font-weight-bold">Network Issues</p>
                    <p className="grey-text">Connected devices may face issues related to network configurations, and this may thwart faster communication and affect the performance of the device. So, it becomes difficult to test devices in different network conditions with the help of network virtualization. Analytics such as CPU, battery consumption, and memory consumption should be noted to know the response of the devices.</p>
                </li> 
                <li>
                    <p className="teel-text font-weight-bold">Testing across various Cloud IoT Platforms</p>
                    <p className="grey-text">Some of the most commonly used cloud IoT platforms that helps in connecting different IoT components are Azure IoT, IBM Watson, and AWS among others. In a rapidly growing IoT environment, when more devices are connected, it may be difficult to replicate a real-time environment for testing as there may be lots of devices that have to be tested on the platform.</p>
                </li> 
                <li>
                    <p className="teel-text font-weight-bold">Inefficient Battery Life</p>
                    <p className="grey-text">Many IoT devices are powered by a battery. Energy-efficient functioning of these devices is very difficult in an IoT environment. For creating an efficient device, components with low power have to be used along with efficient techniques that will de-energize these components when they are not in use.</p>
                </li>  
            </ul>

            <h2 className="mb-lg-3 mt-lg-4">Types of IoT Testing</h2>
            <ul>
                    <li>
                        <p className="teel-text font-weight-bold">Usability</p>
                        <p className="grey-text">There are so many devices of different shape and form factors are used by the users. Moreover, the perception also varies from one user to another. That's why checking the usability of the system is very important in IoT testing.</p>
                    </li>
                    <li>
                        <p className="teel-text font-weight-bold">Compatibility</p>
                        <p className="grey-text">There are lots of devices which can be connected through the I0T system. These devices have varied software and hardware configuration. Therefore, a possible combination is huge. As a result, checking the compatibility in the IoT system is important.</p>
                    </li>
                    <li>
                        <p className="teel-text font-weight-bold">Reliability</p>
                        <p className="grey-text">Among all of them, reliability and scalability for building an IoT test environment which involves simulation of sensors by utilizing virtualization tools and technologies.</p>
                    </li>
                    <li>
                        <p className="teel-text font-weight-bold">Security</p>
                        <p className="grey-text">In the IOT environment, there are many users are accessing a massive amount of data. Thus, it is important to validate user via authentication, have data privacy controls as part of security testing.</p>
                    </li>
                    <li>
                        <p className="teel-text font-weight-bold">Performance</p>
                        <p className="grey-text">This is the most important stage to create a strategic approach for developing and implementing an IoT testing plan.</p>
                    </li>
                </ul>
          </div>
        </div>
      </section>
    </>
  );
}

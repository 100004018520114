
import {React, useState} from "react";
import { Link } from "react-router-dom";
import '../footer/footer.css';
export default function Footer() {
  const [visible, setVisible] = useState(false)
  
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300){
      setVisible(true)
    } 
    else if (scrolled <= 300){
      setVisible(false)
    }
  };
  
  const scrollToTop = () =>{
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };
  
  window.addEventListener('scroll', toggleVisible);
  return (
    <>
      <footer id="footer">
        <div className="footer-top">
          <div className="container">
            <div className="row footer-newsletter">
              <div className="col-lg-6">
                <h3>Get in Touch</h3>

                 
                <div className="social-links">                 
                  <a href="https://www.facebook.com/Crowd4Test" className="facebook" 
                   target="_blank"
                   rel="noreferrer"
                  >
                    <i className="bx bxl-facebook"></i>
                  </a>
                  <a href="https://www.youtube.com/channel/UCt-w9dMYL2foPmggRVWJv0Q?app=desktop" 
                  className="youtube"
                  target="_blank"
                  rel="noreferrer"
                  >
                    <i className="bx bxl-youtube"></i>
                  </a>
                  
                  <a href="https://www.linkedin.com/company/crowd4test" 
                  className="linkedin"
                  target="_blank"
                  rel="noreferrer"
                  >
                    <i className="bx bxl-linkedin"></i>
                  </a>
                  <a href="https://www.instagram.com/" 
                  className="instagram"
                  target="_blank"
                  rel="noreferrer"
                  >
                    <i className="bx bxl-instagram"></i>
                  </a>
                </div>
                <br />
                <br />
                {/* contact us section */}
                <div className="contact-us">
                  <h3>Contact US</h3>
                  <p>
                    If you have any questions, feel free to contact us:
                  </p>
                  <p>
                    Email:admin@crowd4test.com
                  </p>
                  <p>
                    Call: +91 96323 53367
                  </p>
                  <p>
                    Location:
                    556, 14th Main, Sector 3,
                    HSR Layout, Bengaluru, 
                    Karnataka 560102
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="list-group">
                  <Link
                    to="#"
                    className="list-group-item list-group-item-action active"
                    aria-current="true"
                  >
                    {" "}
                    Services
                  </Link>

                  <Link
                    to="/Compatibility"
                    className="list-group-item list-group-item-action"
                  >
                    Compatibility Testing
                  </Link>
                  <Link
                    to="/Game"
                    className="list-group-item list-group-item-action"
                  >
                    Game Testing
                  </Link>

                  <Link
                    to="/AI"
                    className="list-group-item list-group-item-action"
                  >
                    AI Testing
                  </Link>

                  <Link
                    to="/Localization"
                    className="list-group-item list-group-item-action"
                  >
                    Localization Testing
                  </Link>

                  <Link
                    to="/Performance"
                    className="list-group-item list-group-item-action"
                  >
                     Performance Testing
                  </Link>

                  <Link
                    to="/Accessibility"
                    className="list-group-item list-group-item-action"
                  >
                     Accessibility Testing
                  </Link>

                  <Link
                    to="/Automation"
                    className="list-group-item list-group-item-action"
                  >
                     Automation Testing
                  </Link>

                  <Link
                    to="/PaymentBased"
                    className="list-group-item list-group-item-action"
                  >
                     PaymentBased Testing
                  </Link>

                  <Link
                    to="/API"
                    className="list-group-item list-group-item-action"
                  >
                     API Testing
                  </Link>

                  <Link
                    to="/Security"
                    className="list-group-item list-group-item-action"
                  >
                     Security Testing
                  </Link>

                </div>
              </div>
              
              <div className="col-lg-3 col-sm-6">
                <div className="list-group">
                  <Link
                    to="#"
                    className="list-group-item list-group-item-action active"
                    aria-current="true"
                  >
                    Resources
                  </Link>
                  <Link
                    to="/Aboutus"
                    className="list-group-item list-group-item-action"
                  >
                    About
                  </Link>
                  <Link
                    to="/Faq"
                    className="list-group-item list-group-item-action"
                  >
                    FAQ
                  </Link>
                  <Link
                    to="/Webinar"
                    className="list-group-item list-group-item-action"
                  >
                    Webinar
                  </Link>
                  <a
                    href="https://www.crowd4test.com/blog/"
                    className="list-group-item list-group-item-action"
                    target="_blank"
                    rel="noreferrer" 
                  >
                    Blog
                  </a>
                  <a
                    href="https://www.crowd4test.com/platform/#/reviews"
                    className="list-group-item list-group-item-action"
                  >
                    Reviews
                  </a>
                  <Link
                    to="/Pricing"
                    className="list-group-item list-group-item-action"
                  >
                    Pricing
                  </Link>
                  <Link
                    to="/Owncrowd"
                    className="list-group-item list-group-item-action"
                  >
                   Bring your own crowd
                  </Link>
                 
                  <Link
                    to="/Termsandcontion"
                    className="list-group-item list-group-item-action"
                  >
                    Terms & Conditions
                  </Link>
                  <Link
                    to="/Privacy"
                    className="list-group-item list-group-item-action"
                  >
                    Privacy & Policy
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container footer-bottom clearfix">
          <div className="copyright">
            © Copyright{" "}
            <strong>
              <span>Crowd4Test</span>
            </strong>
            . All Rights Reserved
          </div>
        </div>
      </footer>
      <span className="back-to-top d-flex align-items-center justify-content-center" onClick={scrollToTop} style={{display: visible ? 'inline' : 'none'}}>
        <i className="bi bi-arrow-up-short"></i>
      </span>
    </>
  );
}

import React from "react";
import services from "../../assets/img/gif/security.gif";
export default function Localization() {
  return (
    <>
      <section className="inner-page">
        <div className="inner-page-banner d-flex align-items-center justify-content-between">
          <img src={services} alt="" title="" />
        </div>
      </section>
      <section className="inner-page-content">
        <div className="container">
          <div className="section-title">
            <span>Security Testing</span>
            <h2>Security Testing</h2>
            <p>
             Security testing is a process that
             assess software systems, applications,
             or digital products to identify vulnerabilities,
             weaknesses, and potential security threats. 
             It involves various techniques and tools to
             ensure that the software is resistant to
             unauthorized access, data breaches, and other 
             security risks, enhancing its overall security
             posture.
            </p>
          </div>
        </div>

        <div className="box">
          <div className="container">
            <h2 className="mb-lg-3 mt-lg-3">
              Features of Security Testing
            </h2>
            <ul>
              <li>
                <p className="teel-text font-weight-bold">
                 Vulnerability Assessment and Management:
                </p>
                <p className="black-text">
                 Regularly scanning and identifying 
                 vulnerabilities in software, networks,
                 and systems, and promptly addressing them
                 to prevent potential exploits.
                </p>
              </li>

              <li>
                <p className="teel-text font-weight-bold">Application Security Testing (AST):</p>
                <p className="black-text">
                 Incorporating Static Application Security Testing (SAST) 
                 and Dynamic Application Security Testing (DAST) to identify
                 vulnerabilities in software applications during development
                 and runtime.
                </p>
              </li>

              <li>
                <p className="teel-text font-weight-bold">API Security Testing: </p>
                <p className="black-text">
                 Ensuring the security of APIs by 
                 assessing how they handle data and
                 access, guarding against unauthorized 
                 use and data breaches.
                </p>
              </li>

              <li>
                <p className="teel-text font-weight-bold">Cloud Security Testing: </p>
                <p className="black-text">
                Evaluating the security of cloud environments, 
                including infrastructure, services, and data,
                to ensure data protection and compliance.
                </p>
              </li>

              <li>
                <p className="teel-text font-weight-bold">Mobile Application Security Testing: </p>
                <p className="black-text">
                 Evaluating the security of mobile apps to prevent 
                 data leaks, unauthorized access, and other risks.
                </p>
              </li>

              <li>
                <p className="teel-text font-weight-bold">Automated Security Testing: </p>
                <p className="black-text">
                 Using automated tools to streamline the 
                 testing process and identify vulnerabilities
                 more efficiently.
                </p>
              </li>

            </ul>

            <h2 className="mb-lg-3 mt-lg-4">
             The security testing approach involves:
            </h2>
            <ul>
              <li>
                <p className="teel-text font-weight-bold">Requirement Analysis: </p>
                <p className="black-text">
                  Understand security requirements and potential threats.
                </p>
              </li>
              <li>
                <p className="teel-text font-weight-bold">Vulnerability Identification: </p>
                <p className="black-text">
                 Use tools and manual methods to find vulnerabilities.
                </p>
              </li>
              <li>
                <p className="teel-text font-weight-bold">Threat Modeling: </p>
                <p className="black-text">
                 Assess risks and prioritize vulnerabilities.
                </p>
              </li>

              <li>
                <p className="teel-text font-weight-bold">Testing: </p>
                <p className="black-text">
                 Perform various security tests, such as 
                 penetration testing and code review.
                </p>
              </li>

              <li>
                <p className="teel-text font-weight-bold">Validation: </p>
                <p className="black-text">
                  Verify vulnerabilities are addressed
                  and assess overall security.
                </p>
              </li>

              <li>
                <p className="teel-text font-weight-bold">Documentation: </p>
                <p className="black-text">
                  Document findings, actions,
                  and outcomes for reference.
                </p>
              </li>
              
              <li>
                <p className="teel-text font-weight-bold">Continuous Monitoring: </p>
                <p className="black-text">
                 Regularly review and update security measures.
                </p>
              </li>
            </ul>

          </div>
        </div>
      </section>
    </>
  );
}

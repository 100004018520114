import React from "react";
import { Link } from "react-router-dom";
export default function Forgotpassword() {  

  return (
    <section className="inner-page section-bg">
      <div className="container">
      <div className="row ">
          <div className="tester-client col-md-12">
            <h2>Reset Password</h2>
          </div>
        </div>
        <div className="row tester-signUp">
      
          <div className="col-lg-5 mt-5 mt-lg-0 d-flex align-items-stretch fields">
            <form method="post" role="form" className="php-email-form">
              <div className="row">
                <div className="form-group col-md-12 mb-3">
                  <label htmlFor="name">Email ID</label>
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    id="name"
                    required
                  />
                </div>
               
                <div className="col-lg-12 mt-5 mt-lg-0 d-flex align-items-stretch fields">
                  <button className="login-btn">Reset Password</button>
                </div>
                <div className="col-md-6 mt-3  d-flex align-items-stretch">
                  <Link
                    to="/Login"
                    className="link-color"
                  >
                    Login
                  </Link>
                </div>

              </div>
            </form>
          </div>
        </div>
      </div>

     
    </section>
  );
}

import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import { Navigation, Autoplay, EffectFade } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
export default function Ourskill() {
  const slidesPerViewMobile = 1;
  const slidesPerViewDesktop = 4;
  const isMobile = window.matchMedia('(max-width: 768px)').matches;
  return (
    <section id="services" className="featured-services section-bg">
      <div className="container">
        <div className="section-title">
          <span>Our skills</span>
          <h2>Testing Services</h2>
        </div>

        <div className="col-12 p-relative">
          <Swiper className="mySwiper1"
          
            spaceBetween={isMobile ? 0 : 10}
            slidesPerView={isMobile ? slidesPerViewMobile : slidesPerViewDesktop}
            autoplay={true}
            navigation={{ nextEl: ".next", prevEl: ".prev" }}
            modules={[Navigation, Autoplay,EffectFade]}
          >

            <SwiperSlide>
              <div className="img-holder">
                <img src={require("../../assets/img/services/compatibility.jpg")}  alt="" />
              </div>
              <div className="">Compatability Testing</div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="img-holder">
                <img src={require("../../assets/img/services/game-testing.jpg")}  alt="" />
              </div>
              <div className="">Game Testing</div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="img-holder">
                <img src={require("../../assets/img/services/localization.jpg")}  alt="" />
              </div>
              <div className="">Localization Testing</div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="img-holder">
                <img src={require("../../assets/img/services/ai.png")}  alt="" />
              </div>
              <div className="">AI Testing</div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="img-holder">
                <img src={require("../../assets/img/services/accessibility.jpg")}  alt="" />
              </div>
              <div className="">Accessibility Testing</div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="img-holder">
                <img src={require("../../assets/img/services/payment.jpg")}  alt="" />
              </div>
              <div className="">Payment Based Testing</div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="img-holder">
                <img src={require("../../assets/img/services/api.png")}  alt="" />
              </div>
              <div className="">API Testing</div>
            </SwiperSlide>

           
            <SwiperSlide>
              <div className="img-holder">
                <img src={require("../../assets/img/services/performance.jpg")}  alt="" />
              </div>
              <div className="">Performance Testing</div>
            </SwiperSlide>
          

            <SwiperSlide>
              <div className="img-holder">
                <img src={require("../../assets/img/services/Security.jpg")}  alt="" />
              </div>
              <div className="">Security Testing</div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="img-holder">
                <img src={require("../../assets/img/services/automation.jpg")}  alt="" />
              </div>
              <div className="">Automation Testing</div>
            </SwiperSlide>

           
          </Swiper>
          <div className="swiper-button-next next"></div>
          <div className="swiper-button-prev prev"></div>
        </div>
      </div>
    </section>
  );
}

import React from "react";
import services from "../../assets/img/gif/performe.gif";
export default function Performance() {
  return (
    <>
      <section className="inner-page">
        <div className="inner-page-banner d-flex align-items-center justify-content-between">
          <img src={services} alt="" title="" />
        </div>
      </section>
      <section className="inner-page-content">
        <div className="container">
          <div className="section-title">
            <span>Performance Testing</span>
            <h2>Performance Testing</h2>
            <p>
             We test your product's operational capabilities under various loads to guarantee the optimum customer experience.
             With load testing, companies/teams can now identify bottlenecks in their software applications in the right earnest that would otherwise potentially result in huge revenue loss. 
              <br />
              <br />
              Performance Testing is the process of analyzing the quality and capability of a product. It is a testing method performed to determine the system performance in terms of speed, reliability and stability under varying workload. Performance testing is also known as Perf Testing.
            </p>
          </div>
        </div>

        <div className="box">
          <div className="container">
            <h2 className="mb-lg-3 mt-lg-3">Types of Performance Testing</h2>
            <ul>
              <li>
                <p className="teel-text font-weight-bold">Load Testing</p>
                <p className="black-text">
                  Assesses application behavior under expected user loads.
                </p>
              </li>
              <li>
                <p className="teel-text font-weight-bold">Stress Testing</p>
                <p className="black-text">
                 Evaluates system stability beyond normal capacity limits.
                </p>
              </li>
              <li>
                <p className="teel-text font-weight-bold">Endurance Testing</p>
                <p className="black-text">
                 Checks system's sustained performance over prolonged periods
                </p>
              </li>
              <li>
                <p className="teel-text font-weight-bold">Spike Testing</p>
                <p className="black-text">
                 Examines system's reaction to sudden load spikes.
                </p>
              </li>
              <li>
                <p className="teel-text font-weight-bold">Volume Testing</p>
                <p className="black-text">
                 Tests application with large amounts of data.
                </p>
              </li>
              <li>
                <p className="teel-text font-weight-bold">Scalability Testing</p>
                <p className="black-text">
                 Determines system's ability to handle increased loads.
                </p>
              </li>
            </ul>

            <h2 className="mb-lg-3 mt-lg-4">
              Process flow of Performance Testing
            </h2>
            <ul>
              <li>
                <span className="font-weight-bold">
                  Identify your testing environment -
                </span>{" "}
                Know your physical test environment, production environment and
                what testing tools are available.
              </li>
              <li>
                <span className="font-weight-bold">
                  Identify the performance acceptance criteria -{" "}
                </span>
                This includes goals and constraints for throughput, response
                times and resource allocation.
              </li>
              <li>
                <span className="font-weight-bold">
                  Plan &amp; design performance tests -{" "}
                </span>
                Determine how usage is likely to vary amongst end users and
                identify key scenarios to test for all possible use cases.
              </li>
              <li>
                <span className="font-weight-bold">
                  Configuring the test environment -{" "}
                </span>
                Prepare the testing environment before execution. Also, arrange
                tools and other resources.
              </li>
              <li>
                <span className="font-weight-bold">Implement test design - </span>
                Create the performance tests according to your test design.
              </li>
              <li>
                <span className="font-weight-bold">Run the tests - </span>Execute
                and monitor the tests.
              </li>
              <li>
                <span className="font-weight-bold">
                  Analyze, tune and retest -{" "}
                </span>
                Consolidate, analyze and share test results.
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
}

import React from "react";

export default function Mobile() {
  return (
    <>
      <section className="inner-page">
        <div className="inner-page-banner d-flex align-items-center justify-content-between">
          <img
            src={require("../../assets/img/wetest/mobile_app.png")}
            alt=""
            title=""
          />
        </div>
      </section>
      <section className="inner-page-content">
        <div className="container">
          <div className="section-title">
            <span>Mobile Application Testing</span>
            <h2>Mobile Application Testing</h2>
            <p>
              Mobile application testing is a technique in which application
              developed for mobile devices are tested for its functionality,
              consistency, and usability. Mobile application testing can be
              automated or manual mode of testing. All the mobile applications
              either come pre-installed or it can be installed from mobile
              software distribution platform. Mobile application testing majorly
              focuses on field testing, user requirement, and looking at where
              the hardware and software to be tested.
            </p>
          </div>
        </div>

        <div className="box">
          <div className="container">
            <h2 className="mb-lg-3 mt-lg-3">
              Major challenges faced in Mobile Application Testing
            </h2>
            <ul>
              <li>
                <p className="teel-text font-weight-bold">Downloadable</p>
                <p className="grey-text">
                  The application must be easily available for the user to
                  download, generally from the various app stores.
                </p>
              </li>
              <li>
                <p className="teel-text font-weight-bold">Device Accessibility</p>
                <p className="grey-text">
                  Access to the right device is more important because of the
                  growing world of different devices and operating system.
                </p>
              </li>
              <li>
                <p className="teel-text font-weight-bold">Networks</p>
                <p className="grey-text">
                  There are around 400 different mobile networks (CDMA, GSM,
                  FOMA) exist in the world so checking with all these networks
                  will be a challenging task.
                </p>
              </li>
              <li>
                <p className="teel-text font-weight-bold">Test Methods</p>
                <p className="grey-text">
                  For testing mobile application there two methods followed
                  which are: testing on real devices and testing on emulators.
                  Emulators often miss the issue which can be found only by
                  testing on real devices whereas testing in real devices is
                  much expensive and take a long time for the results.
                </p>
              </li>
              <li>
                <p className="teel-text font-weight-bold">Compatibility</p>
                <p className="grey-text">
                  It is also a major thing to look that it works on all devices
                  at any circumstance. Suppose an application may work well at a
                  higher resolution but failed to work in lower resolution.
                </p>
              </li>
            </ul>

            <h2 className="mb-lg-3 mt-lg-4">
              Types of Mobile Application Testing
            </h2>
            <ul>
              <li>
                <p className="teel-text font-weight-bold">Functional Testing</p>
                <p className="grey-text">
                  It will ensure that the application is working as per the
                  customer requirement. Most of the test conducted for it is
                  driven by the user interface.
                </p>
              </li>
              <li>
                <p className="teel-text font-weight-bold">Performance Testing</p>
                <p className="grey-text">
                  It is carried to check the performance of the application
                  under a certain harder condition such as a low battery, poor
                  network, low memory space, less RAM.
                </p>
              </li>
              <li>
                <p className="teel-text font-weight-bold">Usability Testing</p>
                <p className="grey-text">
                  The usage of the application is verified at this stage so that
                  it can be identified whether the application achieves the goal
                  and getting a favorable response from the user side. Usability
                  testing will be the key to commercial success.
                </p>
              </li>
              <li>
                <p className="teel-text font-weight-bold">Security Testing</p>
                <p className="grey-text">
                  To check the vulnerability of being hacked, authentication,
                  and, authorization policies, data securities, session
                  management, and other security standards.
                </p>
              </li>
              <li>
                <p className="teel-text font-weight-bold">Location Testing</p>
                <p className="grey-text">
                  Connection may vary based on the location and networks but we
                  can’t test those condition inside the lab. Only in countries,
                  non-automated testers can perform usability and functional
                  testing.
                </p>
              </li>
              <li>
                <p className="teel-text font-weight-bold">Crowdsourced Testing</p>
                <p className="grey-text">
                  In recent times, crowdsourced testing has become popular as
                  the organization can test the mobile application faster and
                  cheaper using the global community of testers. Due to the
                  emerging diversity of devices and operating system as well as
                  localization need it is difficult to test mobile application
                  with a small in-house testing team. The global distributed
                  team can provide ease of access to different platforms and
                  devices and also can test across multiple locations under
                  different networks. Since the global tester is using the real
                  devices it is cost effective to test the product and will get
                  result faced by the users under the real-world scenario.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
}

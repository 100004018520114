import React from "react";
import services from "../../assets/img/gif/automate2.gif";
export default function Localization() {
  return (
    <>
      <section className="inner-page">
        <div className="inner-page-banner d-flex align-items-center justify-content-between">
          <img src={services} alt="" title="" />
        </div>
      </section>
      <section className="inner-page-content">
        <div className="container">
          <div className="section-title">
            <span>Automation Testing</span>
            <h2>Automation Testing</h2>
            <p>
             Automation testing is a software testing technique that 
             involves using specialized tools and scripts to automate
             the execution of test cases and verify the functionality 
             of a software application. Unlike manual testing, where
             testers perform tests manually, automation testing
             utilizes scripts and software to execute repetitive 
             and complex test scenarios with greater efficiency 
             and accuracy.
            </p>
          </div>
        </div>

        <div className="box">
          <div className="container">
            <h2 className="mb-lg-3 mt-lg-3">
              Automation testing approach 
            </h2>
            <p>
             There are many approaches to test automation,
             however below are the general approaches used
             widely: 
            </p>

            <ul>
              <li>
                <p className="teel-text font-weight-bold">
                 Graphical user interface testing:
                </p>
                <p className="black-text">
                 A testing framework that generates user interface 
                 events such as keystrokes and mouse clicks, and 
                 observes the changes that result in the user interface,
                 to validate that the observable behavior of the program 
                 is correct.
                </p>
              </li>
              <li>
                <p className="teel-text font-weight-bold">
                 API driven testing
                </p>
                <p className="black-text">
                 A testing framework that uses a programming
                 interface to the application to validate the 
                 behaviour under test. Typically API driven 
                 testing bypasses application user interface
                 altogether. It can also be testing public
                 (usually) interfaces to classes, modules
                 or libraries are tested with a variety of 
                 input arguments to validate that the results
                 that are returned are correct.
                </p>
              </li>
              <li>
                <p className="teel-text font-weight-bold">
                 Checklist for Automation Testing
                </p>
                <p className="grey-text">
                 The scope of Automation needs to
                 be determined in detail before the 
                 start of the project. Select the 
                 right automation tool: A tool must 
                 not be selected based on its popularity,
                 but it's fit to the automation requirements.
                </p>
              </li>
        
              <li>Choose an appropriate framework</li>
              <li>
                <span className="font-weight-bold">Scripting Standards - </span>
                Standards have to be followed while writing the scripts for
                Automation. Some of them are:
                <ul className="ul-circle">
                  <li>
                    Create uniform scripts, comments, and indentation of the
                    code.
                  </li>
                  <li>
                    Adequate Exception handling - How error is handled on system
                    failure or unexpected behavior of the application.
                  </li>
                  <li>
                    User-defined messages should be coded or standardized for
                    Error Logging for testers to understand.
                  </li>
                </ul>
              </li>
              <li>
                <span className="font-weight-bold">Measure metrics - </span>Success
                of automation cannot be determined by comparing the manual
                effort with the automation effort but by also capturing the
                following metrics:
                <ul className="ul-circle">
                  <li>Percent of defects found.</li>
                  <li>
                    The time required for automation testing for each and every
                    release cycle.
                  </li>
                  <li>Minimal Time is taken for release.</li>
                  <li>Customer Satisfaction Index.</li>
                  <li>Productivity improvement.</li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
}

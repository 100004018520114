import React, { useState } from 'react';
import logo from "../../assets/img/logo.png";
import '../header/header.css';
import { Modal, Button } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';

const Header = () => {
    const [show, setShow] = useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const IsActive = (path) => {
        const location = useLocation();
        return location.pathname === path;
    }
    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    const closeMobileMenu = () => {
        setIsMobileMenuOpen(false);
    };

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light-white fixed-top pb-1 pt-1">
            <div className="container">
                <Link className="navbar-brand pb-0 pt-0" to="/">
                    <img
                        src={logo}
                        alt="Logo"
                        width="80"
                        height="70"
                        className="d-inline-block align-text-top"
                    />
                </Link>
                <button
                    className="navbar-toggler"
                    type="button"
                    onClick={toggleMobileMenu}
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                {/* <div className={`collapse navbar-collapse ${isMobileMenuOpen ? 'show' : ''}`}>hi</div> */}
                <div className={`collapse navbar-collapse ${isMobileMenuOpen ? 'show' : ''}`} >
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                        <li className="nav-item px-2">
                            <Link className={`nav-link  ${IsActive("/Aboutus") ? "active" : "light"} `} to="/Aboutus" onClick={closeMobileMenu}>About</Link>
                        </li>
                        <li className="nav-item px-2 dropdown">
                            <Link
                                className="nav-link dropdown-toggle"
                                to="#"
                                id="weTestDropdown"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                We Test
                            </Link>
                            <ul className="dropdown-menu" aria-labelledby="weTestDropdown">
                                <li>
                                    <Link className={`dropdown-item ${IsActive("/Mobile") ? "active bg-light" : "light"}`} to="/Mobile" onClick={closeMobileMenu}>Mobile</Link>
                                </li>
                                <li>
                                    <Link className={`dropdown-item ${IsActive("/Desktop") ? "active bg-light" : "light"}`} to="/Desktop" onClick={closeMobileMenu}>Desktop</Link>
                                </li>
                                <li>
                                    <Link className={`dropdown-item ${IsActive("/Website") ? "active bg-light" : "light"}`} to="/Website" onClick={closeMobileMenu}>Website</Link>
                                </li>
                                <li>
                                    <Link className={`dropdown-item ${IsActive("/Arvr") ? "active bg-light" : "light"}`} to="/Arvr" onClick={closeMobileMenu}>AR/VR</Link>
                                </li>
                                <li>
                                    <Link className={`dropdown-item ${IsActive("/Iot") ? "active bg-light" : "light"}`} to="/Iot" onClick={closeMobileMenu}>IoT</Link>
                                </li>
                            </ul>
                        </li>
                        <li className="nav-item dropdown px-2">
                            <Link
                                className="nav-link dropdown-toggle"
                                to="#"
                                id="servicesDropdown"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                Services
                            </Link>
                            <ul className="dropdown-menu" aria-labelledby="servicesDropdown">
                                <li>
                                    <Link className={`dropdown-item ${IsActive("/Compatibility") ? "active bg-light" : "light"}`} to="/Compatibility" onClick={closeMobileMenu}>Compatibility Testing</Link>
                                </li>
                                <li>
                                    <Link className={`dropdown-item ${IsActive("/Game") ? "active bg-light" : "light"}`} to="/Game" onClick={closeMobileMenu}>Game Testing</Link>
                                </li>
                                <li>
                                    <Link className={`dropdown-item ${IsActive("/Localization") ? "active bg-light" : "light"}`} to="/Localization" onClick={closeMobileMenu}>Localization Testing</Link>
                                </li>
                                <li>
                                    <Link className={`dropdown-item ${IsActive("/AI") ? "active bg-light" : "light"}`} to="/AI" onClick={closeMobileMenu}>AI Testing</Link>
                                </li>
                                <li>
                                    <Link className={`dropdown-item ${IsActive("/Accessibility") ? "active bg-light" : "light"}`} to="/Accessibility" onClick={closeMobileMenu}>Accessibility Testing</Link>
                                </li>
                                <li>
                                    <Link className={`dropdown-item ${IsActive("/PaymentBased") ? "active bg-light" : "light"}`} to="/PaymentBased" onClick={closeMobileMenu}>Payment-Based Testing</Link>
                                </li>
                                <li>
                                    <Link className={`dropdown-item ${IsActive("/API") ? "active bg-light" : "light"}`} to="/API" onClick={closeMobileMenu}>API Testing</Link>
                                </li>
                                <li>
                                    <Link className={`dropdown-item ${IsActive("/Performance") ? "active bg-light" : "light"}`} to="/Performance" onClick={closeMobileMenu}>Performance Testing</Link>
                                </li>
                                <li>
                                    <Link className={`dropdown-item ${IsActive("/Security") ? "active bg-light" : "light"}`} to="/Security" onClick={closeMobileMenu}>Security Testing</Link>
                                </li>
                                <li>
                                    <Link className={`dropdown-item ${IsActive("/Automation") ? "active bg-light" : "light"}`} to="/Automation" onClick={closeMobileMenu}>Automation Testing</Link>
                                </li>
                            </ul>
                        </li>
                        <li className="nav-item px-2 dropdown">
                            <Link
                                className="nav-link dropdown-toggle"
                                to="#"
                                id="resourcesDropdown"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                Resources
                            </Link>
                            <ul className="dropdown-menu" aria-labelledby="resourcesDropdown">
                                <li>
                                    <Link className={`dropdown-item ${IsActive("/Faq") ? "active bg-light" : "light"}`} to="/Faq" onClick={closeMobileMenu}>FAQ</Link>
                                </li>
                                <li>
                                    <Link className={`dropdown-item ${IsActive("/Webinar") ? "active bg-light" : "light"}`} to="/Webinar" onClick={closeMobileMenu}>Webinar</Link>
                                </li>
                                <li>
                                    <Link className={`dropdown-item ${IsActive("https://www.crowd4test.com/blog/") ? "active bg-light" : "light"}`} to="https://www.crowd4test.com/blog/" target='_blank' onClick={closeMobileMenu}>Blog</Link>
                                </li>
                                <li>
                                    <Link className={`dropdown-item ${IsActive("https://www.crowd4test.com/platform/#/reviews") ? "active bg-light" : "light"}`} to="https://www.crowd4test.com/platform/#/reviews" target='_blank' onClick={closeMobileMenu}>Reviews</Link>
                                </li>
                                <li>
                                    <Link className={`dropdown-item ${IsActive("/Pricing") ? "active bg-light" : "light"}`} to="/Pricing" onClick={closeMobileMenu}>Pricing</Link>
                                </li>
                                <li>
                                    <Link className={`dropdown-item ${IsActive("/Owncrowd") ? "active bg-light" : "light"}`} to="/Owncrowd" onClick={closeMobileMenu}>Bring your own crowd</Link>
                                </li>
                            </ul>
                        </li>
                        <li className="nav-item px-2">
                            <Link className={`nav-link  ${IsActive("/Contact") ? "active" : "light"} `} to="/Contact" onClick={closeMobileMenu}>Contact</Link>
                        </li>
                        <li className="nav-item ">
                            <Link style={{ height: "35px", lineHeight: "40px", display: "flex", justifyContent: "center", alignItems: "center" }} className="btn btn-primary btn-lg getstarted nav-link b-button  signUp px-4" to="https://www.crowd4test.com/platform/#/login" target='_blank'>Login</Link>
                        </li>
                        <li className="nav-item ">
                            <Link style={{ height: "35px", lineHeight: "40px", display: "flex", justifyContent: "center", alignItems: "center" }} className="btn btn-primary btn-lg getstarted nav-link b-button signUp px-4  mobile-view-margin-top" to="#" onClick={handleShow}>Sign Up</Link>
                        </li>
                        <br>
                        </br>
                        <li className="nav-item">
                            <Link 
                              style={{ height: "35px", lineHeight: "40px", display: "flex", justifyContent: "center", alignItems: "center" }} 
                             className="btn btn-primary btn-lg getstarted nav-link b-button signUp px-4" 
                             to="https://calendly.com/info-mza/30min" 
                              target='_blank'
                            >
                             Book A Demo
                            </Link>
                        </li>


                    </ul>
                </div>
            </div>
            < Modal show={show} onHide={handleClose} centered>
                <Modal.Body>

                    <div className="container text-center" style={{ padding: "50px" }}>
                        <div className="row">
                            <div className="col-12"> <h3>Are you a..</h3></div>
                            <div className="col">
                                <Link style={{ height: "40px", lineHeight: "40px" }} className="btn btn-primary btn-lg getstarted nav-link b-button signUp" to="https://www.crowd4test.com/platform/#/testersignup" target='_blank'>Tester</Link>
                            </div>
                            <div class="col">
                                <Link style={{ height: "40px", lineHeight: "40px" }} className="btn btn-primary btn-lg getstarted nav-link b-button signUp" to="https://www.crowd4test.com/platform/#/clientsignup" target='_blank'>Client</Link>
                            </div>
                        </div>
                        
                    </div>
                </Modal.Body>
            </Modal>
        </nav>
    );
};

export default Header;
import React from "react";

export default function Arvr() {
  return (
    <>
      <section className="inner-page">
        <div className="inner-page-banner d-flex align-items-center justify-content-between">
          <img
            src={require("../../assets/img/wetest/ar_vr.png")}
            alt=""
            title=""
          />
        </div>
      </section>
      <section className="inner-page-content">
        <div className="container">
          <div className="section-title">
            <span>AR & VR Testing</span>
            <h2>AR & VR Testing</h2>
            <p>
              <b>Augment Reality (AR)</b> is a hands-on experience of a
              real-world environment where the objects that exist in the
              real-world are upgraded by machine-generated information,
              sometimes across multiple sensory modalities, including visual,
              auditory, haptic, somatosensory, and olfactory. The first user
              world augmented reality experiences were used largely in the
              entertainment and gaming fields but now its been expanding to
              other industries such as knowledge sharing, educating, managing
              the information flood and organizing distant meetings.
              <br /> <br />
              <b> Virtual reality (VR)</b> is an interactive technology which
              was an experience in taking place within a limited environment. It
              comprises mainly auditory and visual feedback, but sometimes it
              also allows other types of sensory feedback. This environment can
              be similar to the real world or it can be splendid, to be then
              used for a variety of applications. VR is most commonly used in
              the entertainment sector such as video gaming and 3D cinema.
              Consumers virtual reality headsets were first released by video
              game companies in the early-mid 1990s. In 2010, the commercial VR
              headsets were released by Oculus (Rift), HTC (Vive) and Sony
              (PlayStation VR), setting off a new wave of application
              development.
            </p>
          </div>
        </div>

        <div className="box">
          <div className="container">
            <h2 className="mb-lg-3 mt-lg-3">
              Major challenges faced in AR & VR Testing
            </h2>
            <ul>
              <li>
                <p className="teel-text font-weight-bold">Expensive</p>
                <p className="grey-text">
                  VR right now comes at too high of a price point for many.
                  Microsoft, Google, and Facebook have already invested billions
                  into the VR market, allowing for some very powerful hardware
                  like the Oculus Rift to hit the market in the last year.
                  According to Riccitiello, the issue is that consumers weren't
                  quite ready for it all.
                </p>
              </li>
              <li>
                <p className="teel-text font-weight-bold">Content</p>
                <p className="grey-text">
                  The number one challenge right now is that compelling content
                  that's habit forming from a consumer perspective. There are
                  plenty of high graphics games for VR as well as many emerging
                  use cases on the enterprise side, VR has still in the research
                  state for its booming app use case or program which will make
                  the VR essential for consumers and business.
                </p>
              </li>
              <li>
                <p className="teel-text font-weight-bold">Mobile Friendly</p>
                <p className="grey-text">
                  If you've tried any VR experience you've probably noticed one
                  very common aspect there's a lot of cords. The problem is with
                  the cord if a user is effectively locked into a VR box,
                  restricted by the cord, it essentially defeats the purpose of
                  having them immersed in a supposed 360-degree environment, It
                  won't work unless you can experience things the way you do in
                  real life.
                </p>
              </li>
              <li>
                <p className="teel-text font-weight-bold">5G Speed</p>
                <p className="grey-text">
                  VR and AR technologies, on all ends from collective enterprise
                  to all the consumer entertainment, are already demanding more
                  than ever from our computer hardware, and soon they'll be
                  doing it for the data communication speeds as well. The
                  solution for many puzzles will be solved in the further
                  development of 5G opening up more of the spectrum for faster
                  wireless communication. The 4G communication today is based on
                  sub-6 GHz which is not enough and you need to expand the
                  spectrum to much higher bands.
                </p>
              </li>
              <li>
                <p className="teel-text font-weight-bold">Cybersecurity</p>
                <p className="grey-text">
                  With any connected technology it's only a matter of time
                  before cybersecurity issues are raised. While there has yet to
                  be any sort of high-profile hack or cyber attack conducted via
                  VR, but anyone who follows cybersecurity should consider it
                  only a matter of whole time given and out of it only a little
                  attention is being paid to the intersection of VR and
                  cybersecurity right now.
                </p>
              </li>
            </ul>

            <h2 className="mb-lg-3 mt-lg-4">Types of AR and VR Testing</h2>
            <ul>
              <li>
                <p className="teel-text font-weight-bold">
                  UX and Specification Testing
                </p>
                <p className="grey-text">
                  Before any testing even begins, QA engineers sit down to
                  review the VR/AR product’s scope requirements. After coming to
                  know about all the requirements, they will prepare a flowchart
                  of potential use cases. This helps all the engineers to
                  understand all the potential scenarios for user engagement.
                  The exercise provides a integrated view of the product far
                  more thorough than a simple review of what wireframes can
                  deliver.
                </p>
              </li>
              <li>
                <p className="teel-text font-weight-bold">Real Device Testing</p>
                <p className="grey-text">
                  VR and AR do always depend on appropriate hardware. The only
                  way to assure that these products are functioning properly is
                  to test using the devices which are listed in the
                  requirements. These might include the Oculus Rift or the HTC
                  Vive, both of them are connected to the personal computers for
                  a powerfully immersive VR experience. Other hardware, like the
                  Samsung Gear or Google Daydream, will work with the user’s
                  smartphone to create a good mobile VR experience.
                </p>
              </li>
              <li>
                <p className="teel-text font-weight-bold">Compatibility Testing</p>
                <p className="grey-text">
                  This phase helps to make sure that particular teams do not
                  face any kind of surprises actions when they go to market.
                  Compatibility testing helps to analyze the performance of the
                  app when it is being accessed by devices with lower system
                  specifications, or on a device that it hasn’t been optimized
                  for. It also helps catch some of the non-functional issues,
                  such as device overheating
                </p>
              </li>
              <li>
                <p className="teel-text font-weight-bold">Accessibility Testing</p>
                <p className="grey-text">
                  Using VR/AR products can arise with some serious physical
                  consequences. Headaches, motion sickness, seizures, eye
                  strain, and other bodily harm are a few of the worst case
                  scenarios that testers need to be aware of all these things.
                  Though the full focus is on the goal, it’s also vital to
                  reduce the discomfort of the user as much as possible and, by
                  extension, limit the liability of the company building the
                  product.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
}

import React from "react";
import terms from "../../assets/img/services/privacy.jpg";
export default function Termsandcontion() {
  return (
    <>
      <section className="inner-page">
        <div className="inner-page-banner d-flex align-items-center justify-content-between">
          <img src={terms} alt="" title="" />
        </div>
      </section>
      <section className="inner-page-content">
        <div className="box">
          <div className="container">
            <h2 className="mb-lg-3 mt-lg-3">PRIVACY & POLICY</h2>
            <p>
             Please go through our privacy policy and understand better what are the guidelines that we follow
            </p>
            <ul>
              <li>
               crowd4test ("we", "us", "our") is dedicated to protecting the privacy and personal information of our users ("you", "your"). This Privacy Policy outlines our practices regarding the collection, use, and disclosure of personal information when you use our services. It also describes how we process personal data for individuals in the European Union (EU) in accordance with the General Data Protection Regulation (GDPR). We encourage you to review our website regularly for updates to this policy.
               "Personal Information" refers to information about an identified or reasonably identifiable individual. This Privacy Policy applies to personal information collected and/or held by crowd4test.
              </li>
              <br>
              </br>
              <h3>1.Personal Information we Collect and hold</h3>
            </ul>
            <ul>
              <h3>1.1Type of Personal Information </h3>
              <li>
                1.1.1 Information obtained for Interaction Purpose
              </li>
              <p>
               We collect your personal information in order to provide our products, services, and customer support, which are provided through many platforms including but not limited to: websites, phone apps, emails, and telephones. The specific platform and product, service, or support you interact with may affect the personal data we collect.
              </p>
              <li>
               1.1.2. Non-Personal Information linked with existing Personal Information
              </li>
              <p>
               Not all information requested, collected, and processed by us is “Personal Information” as it does not identify you as a specific natural person. This will include majority of “User Generated Content” that you provide us with the intention of sharing with other users. Such “Non-Personal Information” is not covered by this privacy policy. However, as non-personal information may be used in aggregate or be linked with existing personal information; when in this form it will be treated as personal information. As such, this privacy policy will list both types of information for the sake of transparency.
              </p>
              <li>
               1.1.3. Personal Information not intended for collection of particular types of information
              </li>
              <p>
               In some situation you may provide us with personal information without us asking for it, or through means not intended for the collection of particular types of information. Whilst we may take reasonable steps to protect this data, you will have bypassed our systems, processes, and control and thus the information provided will not be governed by this privacy policy.
              </p>
              <li>
               1.1.4. Information provided over platforms outside our control
              </li>
              <p>
               In some situations you may provide us personal information over platforms that are outside our control; for example through social media or forums. Whilst any information collected by us is governed by this Privacy Policy, the platform by which it was communicated will be governed by its own Privacy Policy.
              </p>
            </ul>
            <ul>
              <h3>1.2 How we collect personal information</h3>
              <li>
                1.2.1 Information that you specifically give us
              </li>
              <p>
              While you use our products, services and customer support, you may be asked to provide certain types of personal information. This might happen through our website, applications, emails, online chat systems, telephone, paper forms, or in-person meetings. We may request, collect, or process the following information:
              </p>
              <li>
                1. Account Details – username,password,profile picture.
              </li>
              <li>
                2. Contact Details – email address,phone number.
              </li>
              <li>
                3. Location Details – physical address, billing address, timezone.
              </li>
              <li>
                4. Identity Details – full name, proof of identity (e.g. drivers licence, passport), proof of address (e.g. utility bill), photograph.
              </li>
              <li>
                5. Financial Information – credit card details, wire transfer details, payment processor details (e.g. instamojo, paypal), tax numbers.
              </li>
              <li>
                6. User Generated Content – project descriptions and attachments, payout description, user profiles, user reviews, contest descriptions and attachment, user messages etc.
              </li>
              <li>
              <br>
              </br>
               1.2.2. Information that we collect from others
              </li>
              <p>
               You might give us permission to connect to your account on other platforms to collect personal information. (1.1.4) This includes but is not limited to Facebook, LinkedIn, and Google. Information collected will be governed by this Privacy Policy. You can stop us from collecting data from other platforms by removing our access on the other platform or by contacting our support team.

               Since you have the ability to invite non-users to our platform by providing contact details such as email address, we may collect and store the information to contact the non-user and to prevent abuse of the invite systems.

               Your payment provider may transmit information about the payment that we may collect or process.

               In some situations, your personal information may be collected from public sources.

               We may collect or process the following information:
              </p>
              <li>
               1. Basic Details – username, profile picture. 
              </li>
              <li>
                2. Contact Details – email address, phone number.
              </li>
              <li>
                3. Location Details – Physical Address, billing address, timezone.
              </li>
              <li>
                4. Financial Information – payment account details (e.g. paypal email address and physical address), and wire transfer details.
              </li>
              <li>
                5. List of contacts – email provider address book.
              </li>
              <li>
               6. User Generated Content – user profile.
              </li>
              <br>
              </br>
              <li>
               <h3>1.2.3 Information we collect as you use our service</h3>
              </li>
              <p>
                We maintain records of the interactions we have with you, including the products, services and customer support we have provided. This includes your interactions with our platform such as when you have viewed a page or clicked a button.

                In order to deliver certain products or services we may passively collect your GPS coordinates, where available from your device. Most modern devices such as smartphones will display a permission request when our platform requests this data.

                When we are contacted we may collect personal information that is intrinsic to the communication. For example, if we are contacted via email, we will collect the email address used.
              </p>
              <p>
               We may collect or process the following information:
              </p>
              <li>
                1. Metadata – IP address, computer and connection information, referring web page, standard web log information, language settings, timezone, etc.
              </li>
              <li>
                2. Device Information – device identifier, device type, device plugins, hardware capabilities, etc.
              </li>
              <li>
                3. Location – GPS position.
              </li>
              <li>
                4. Actions – pages viewed, buttons clicked, time spent viewing, search keywords, etc.
              </li>
              <li>
               1.1.4. Links to other sites
              </li>
              <p>
               On our website, you will encounter links to third party websites. These links may be from us, or they may appear as content generated by other users. These linked sites are not under our control and thus we are not responsible for their actions. Before providing your personal information via any other website, we advise you to examine the terms and conditions of using that website and its privacy policy.
              </p>
            </ul>
            <h3>2. When we disclose personal information</h3>
            <ul>
            <li>
              2.1. Our third party service providers
            </li>
            <p>
             Your personal information may be held or processed on our behalf outside India, including ‘in the cloud’, by our third party service providers. Our third party service providers are bound by contract to only use your personal information on our behalf, under our instructions.
            </p>
            <p>
             Our third party service providers include: 
            </p>
            <li>
             Cloud hosting, storage, networking and related providers
            </li>
            <li>
             SMS providers
            </li>
            <li>
             Payment and banking providers
            </li>
            <li>
             Marketing and analytics providers
            </li>
            <li>
             Security providers
            </li>
            </ul>
            <h3>2.2. Third party applications</h3>
            <p>
              Through the oprimes API, it is possible for you to grant third party applications access to their oprimes account. Depending on the permissions that are granted, these applications may be able to access some personal information or do actions on the your behalf. These third party applications are not controlled by us and will be governed by their own privacy policy. You can remove third party applications from accessing your data through your settings.
            </p>
            <br>
            </br>
            <h3>3. Accessing, correcting, or downloading your personal information</h3>
            <p>
             You have the right to request access to the personal information oprimes holds about you. Unless an exception applies, we must allow you to see the personal information we hold about you, within a reasonable time period, and without unreasonable expense for no charge. Most personal information can be accessed by logging into your account. If you wish to access information that is not accessible through the platform, or wish to download all personal information we hold on you in a portable data format, please contact our Privacy Officer at info@crowd4test.com
             You also have the right to request the correction of the personal information we hold about you. All your personal information can be updated through the user settings pages. If you require assistance please contact our customer support at info@crowd4test.com 
            </p>
            <br>
            </br>
            <h3>4. To contact our Privacy Officer</h3>
            <p>If you have an enquiry or a complaint about the way we handle your personal information, or to seek to exercise your privacy rights in relation to the personal information we hold about you, you may contact our Privacy Officer as follows:
              <br>
              </br>
              By Email:
              <br>
              </br>
              info@crowd4test.com
            </p>
          </div>
        </div>
      </section>
    </>
  );
}
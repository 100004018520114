import React from "react";

export default function Website() {
  return (
    <>
      <section className="inner-page">
        <div className="inner-page-banner d-flex align-items-center justify-content-between">
          <img
            src={require("../../assets/img/wetest/website.png")}
            alt=""
            title=""
          />
        </div>
      </section>
      <section className="inner-page-content">
        <div className="container">
          <div className="section-title">
            <span>Website Testing</span>
            <h2>Website Testing</h2>
            <p>
              Website testing is also known as web testing that targets on
              testing of websites. A complete test of the website before making
              it live can help to identify the issues such as security,
              functionality, accessibility for PwD people, adapt with multiple
              desktop, devices, and operating system. Web security testing gives
              an idea of whether web-based applications meet the requirement
              when they are subjected to malicious input data.
            </p>
          </div>
        </div>

        <div className="box">
          <div className="container">
            <h2 className="mb-lg-3 mt-lg-3">
              Major challenges faced in Website Testing
            </h2>
            <ul>
              <li>
                <p className="teel-text font-weight-bold">Browser Compatibility</p>
                <p className="grey-text">
                  The website which is being tested should be able to adapt on
                  all types of browsers, in current scenario people are relying
                  on all type of browsers such as chrome, firefox, internet
                  explorer, opera, safari and much more.
                </p>
              </li>
            </ul>

            <h2 className="mb-lg-3 mt-lg-3">Checklist for Website Testing</h2>
            <ul>
              <li>
                <p className="teel-text font-weight-bold">Functional Testing</p>
                <p className="grey-text">
                  The checklist will be focused on all the links in web pages,
                  database connection, lead generation forms which are used to
                  collect information from the users. The important links to be
                  checked for internal links, external links, mail links, and
                  broken links
                </p>
              </li>
              <li>
                <p className="teel-text font-weight-bold">Performance Testing</p>
                <p className="grey-text">
                  The performance of the website has been tested in two ways web
                  load testing i.e. the time taken by a page to load during a
                  peak time and web stress testing i.e. to check the performance
                  of a website beyond its limit.
                </p>
              </li>
              <li>
                <p className="teel-text font-weight-bold">Usability Testing</p>
                <p className="grey-text">
                  The process by which the computer-human interaction takes
                  place and it has been measured and the negative are identified
                  for correction. Usability testing encounters the ease of
                  learning, navigation, user satisfaction, and general
                  appearance.
                </p>
              </li>
              <li>
                <p className="teel-text font-weight-bold">Compatibility Testing</p>
                <p className="grey-text">
                  Compatibility of your website is tested which is the most
                  important aspect it can be achieved by checking the browser
                  compatibility, operating system compatibility, mobile browsing
                  compatibility, and printing options.
                </p>
              </li>
              <li>
                <p className="teel-text font-weight-bold">Interface Testing</p>
                <p className="grey-text">
                  The server-side interface is tested in this phase by checking
                  the compatibility of the server with hardware, software,
                  network, and database. The major interface which an interface
                  testing undergoes is web server &amp; application server
                  interface and application server &amp; database server
                  interface.
                </p>
              </li>
              <li>
                <p className="teel-text font-weight-bold">Security Testing</p>
                <p className="grey-text">
                  Security can be stated on how well your website fight with
                  external factors such as a virus, malware, hacking, etc. The
                  top things to look for in security testing are network
                  scanning, vulnerability scanning, password cracking, log
                  review, integrity checker, and virus detector.
                </p>
              </li>
            </ul>

            <h2 className="mb-lg-3 mt-lg-3">Types of Website Testing</h2>
            <ul>
              <li>
                <p className="teel-text font-weight-bold">
                  Simple Static Website Testing
                </p>
                <p className="grey-text">
                  The simple static website is also known as an information
                  website which doesn’t contain major functionalities and fully
                  depends on UI design. The testing will be done for the website
                  design, links on the pages, content proofreading, scroll bar,
                  form submission pages.
                </p>
              </li>
              <li>
                <p className="teel-text font-weight-bold">
                  Dynamic Web Application Testing
                </p>
                <p className="grey-text">
                  The dynamic website is the combination of both front-end (HTML
                  and CSS) and back-end (PHP, Javascript, and ASP). The testing
                  will be performed for the call to action buttons, broken
                  images, and broken links.
                </p>
              </li>
              <li>
                <p className="teel-text font-weight-bold">
                  E-commerce Website Testing
                </p>
                <p className="grey-text">
                  An e-commerce website is complicated when compared with the
                  other two website testing. This testing includes the following
                  checklist no. of checkouts, cart list, filter options, signup/
                  login page, payment page, email generation, and live chats.
                </p>
              </li>
              <li>
                <p className="teel-text font-weight-bold">Mobile Website Testing</p>
                <p className="grey-text">
                  Testing a mobile website is somewhat complex rather than
                  testing other types of websites. It will have separate designs
                  and you need to be much careful while testing the
                  functionalities. Things to follow while testing mobile website
                  are logo/ image size, compatibility test, performance, links,
                  scroll depth, and page navigation.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
}

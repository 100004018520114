import React, { useEffect } from 'react'
import Isotope from 'isotope-layout'
export default function Portfolio() {

   // init one ref to store the future isotope object
   const isotope = React.useRef()
   // store the filter keyword in a state
   const [filterKey, setFilterKey] = React.useState('Social-Media')
 
   // initialize an Isotope object with configs
   useEffect(() => {
     isotope.current = new Isotope('.portfolio-container', {
       itemSelector: '.portfolio-item',
       layoutMode: 'fitRows',
     })
     // cleanup
     return () => isotope.current.destroy()
     
   }, [])
 
   // handling filter key change
   useEffect(() => {
     filterKey === '*'
       ? isotope.current.arrange({filter: `Social-Media`})
       : isotope.current.arrange({filter: `.${filterKey}`})
   }, [filterKey])
 
   const handleFilterKeyChange = key => () => setFilterKey(key)
  
  return (
    <section id="portfolio" className="portfolio">
      <div className="container">

        <div className="section-title">
          <span>We worked with</span>
          <h2>We worked with</h2>
         
        </div>

        <div className="row">
          <div className="col-lg-12 d-flex justify-content-center">
            <ul id="portfolio-flters" className=''>
                <li>
                 <button onClick={handleFilterKeyChange('Social-Media')}>Social Media & Engagement</button>
                </li>

                <li>
                 <button onClick={handleFilterKeyChange('Communication')}>Communication</button>
                </li>

                <li>
                 <button onClick={handleFilterKeyChange('Media-Entertainment')}>Media & Engagement</button>
                </li>

                <li>
                 <button onClick={handleFilterKeyChange('Gaming')}>Gaming</button>
                </li>

                <li>
                 <button onClick={handleFilterKeyChange('Healthcare')}>Healthcare</button>
                </li>

                <li>
                 <button onClick={handleFilterKeyChange('Travel-Hospitality')}>Travel & Hospitality</button>
                </li>

                <li>
                 <button onClick={handleFilterKeyChange('Financial-Services')}>Financial Services</button>
                </li>

                <li>
                 <button onClick={handleFilterKeyChange('Retail')}>Retail</button>
                </li>          
              
            </ul>
          </div>
        </div>

        <div className="row portfolio-container">

          <div className="col-lg-4 col-md-6 portfolio-item Gaming">
            <img src={require("../../assets/img/portfolio/page_2.JPG")} className="img-fluid" alt=""/>
            <div className="portfolio-info">
              <h4>Gaming</h4>
              <p>Gaming</p>
              
              
            </div>
          </div>

          <div className="col-lg-4 col-md-6 portfolio-item Media-Entertainment">
            <img src={require("../../assets/img/portfolio/page_3.JPG")} className="img-fluid" alt=""/>
            <div className="portfolio-info">
              <h4> Media-Entertainment</h4>
              <p> Media-Entertainment</p>         
              
            </div>
          </div>

          <div className="col-lg-4 col-md-6 portfolio-item filter-app">
            <img src={require("../../assets/img/portfolio/page_4.JPG")} className="img-fluid" alt=""/>
            <div className="portfolio-info">
              <h4>App 2</h4>
              <p>App</p>
             
              
            </div>
          </div>

          <div className="col-lg-4 col-md-6 portfolio-item Travel-Hospitality">
            <img src={require("../../assets/img/portfolio/page_6.JPG")} className="img-fluid" alt=""/>
            <div className="portfolio-info">
              <h4>Travel-Hospitality</h4>
              <p>Travel-Hospitality</p>
             
              
            </div>
          </div>

          <div className="col-lg-4 col-md-6 portfolio-item Financial-Services">
            <img src={require("../../assets/img/portfolio/page_7.JPG")} className="img-fluid" alt=""/>
            <div className="portfolio-info">
              <h4>Financial-Services</h4>
              <p>Financial-Services</p>
              
              
            </div>
          </div>

          <div className="col-lg-4 col-md-6 portfolio-item Media-Entertainment">
            <img src={require("../../assets/img/portfolio/page_8.JPG")} className="img-fluid" alt=""/>
            <div className="portfolio-info">
              <h4>Media-Entertainment</h4>
              <p>Media-Entertainment</p>
             
              
            </div>
          </div>

          <div className="col-lg-4 col-md-6 portfolio-item filter-card">
            <img src={require("../../assets/img/portfolio/page_9.JPG")} className="img-fluid" alt=""/>
            <div className="portfolio-info">
              <h4>filter-card</h4>
              <p>filter-card</p>
              
              
            </div>
          </div>

          <div className="col-lg-4 col-md-6 portfolio-item Social-Media">
            <img src={require("../../assets/img/portfolio/page_10.JPG")} className="img-fluid" alt=""/>
            <div className="portfolio-info">
              <h4>Social-Media</h4>
              <p>Social-Media</p>
             
              
            </div>
          </div>

          <div className="col-lg-4 col-md-6 portfolio-item Media-Entertainment">
            <img src={require("../../assets/img/portfolio/page_11.JPG")} className="img-fluid" alt=""/>
            <div className="portfolio-info">
              <h4>Media-Entertainment</h4>
              <p>Media-Entertainment</p>
              
              
            </div>
          </div>

          <div className="col-lg-4 col-md-6 portfolio-item Travel-Hospitality">
            <img src={require("../../assets/img/portfolio/page_12.JPG")} className="img-fluid" alt=""/>
            <div className="portfolio-info">
              <h4>Travel-Hospitality</h4>
              <p>Travel-Hospitality</p>
              
              
            </div>
          </div>



          <div className="col-lg-4 col-md-6 portfolio-item Financial-Services">
            <img src={require("../../assets/img/portfolio/page_13.JPG")} className="img-fluid" alt=""/>
            <div className="portfolio-info">
              <h4>Financial-Services</h4>
              <p>Financial-Services</p>
              
              
            </div>
          </div>



          <div className="col-lg-4 col-md-6 portfolio-item Travel-Hospitality">
            <img src={require("../../assets/img/portfolio/page_14.JPG")} className="img-fluid" alt=""/>
            <div className="portfolio-info">
              <h4>Travel-Hospitality</h4>
              <p>Travel-Hospitality</p>
              
              
            </div>
          </div>



          <div className="col-lg-4 col-md-6 portfolio-item filter-web">
            <img src={require("../../assets/img/portfolio/page_15.JPG")} className="img-fluid" alt=""/>
            <div className="portfolio-info">
              <h4>filter-web</h4>
              <p>filter-web</p>
              
              
            </div>
          </div>



          <div className="col-lg-4 col-md-6 portfolio-item Social-Media">
            <img src={require("../../assets/img/portfolio/page_16.JPG")} className="img-fluid" alt=""/>
            <div className="portfolio-info">
              <h4>Social-Media</h4>
              <p>Social-Media</p>
              
              
            </div>
          </div>



          <div className="col-lg-4 col-md-6 portfolio-item filter-web">
            <img src={require("../../assets/img/portfolio/page_17.JPG")} className="img-fluid" alt=""/>
            <div className="portfolio-info">
              <h4>Web 3</h4>
              <p>Web</p>
              
              
            </div>
          </div>


          <div className="col-lg-4 col-md-6 portfolio-item Social-Media">
            <img src={require("../../assets/img/portfolio/page_18.JPG")} className="img-fluid" alt=""/>
            <div className="portfolio-info">
              <h4>Social-Media </h4>
              <p>Social-Media</p>
              
              
            </div>
          </div>

          <div className="col-lg-4 col-md-6 portfolio-item Gaming">
            <img src={require("../../assets/img/portfolio/page_19.JPG")} className="img-fluid" alt=""/>
            <div className="portfolio-info">
              <h4>Gaming</h4>
              <p>Gaming</p>
              
              
            </div>
          </div>

          <div className="col-lg-4 col-md-6 portfolio-item Retail">
            <img src={require("../../assets/img/portfolio/page_20.JPG")} className="img-fluid" alt=""/>
            <div className="portfolio-info">
              <h4>Retail</h4>
              <p>Retail</p>
              
              
            </div>
          </div>


          <div className="col-lg-4 col-md-6 portfolio-item Social-Media">
            <img src={require("../../assets/img/portfolio/page_21.JPG")} className="img-fluid" alt=""/>
            <div className="portfolio-info">
              <h4>Social-Media</h4>
              <p>Social-Media</p>
              
              
            </div>
          </div>

          <div className="col-lg-4 col-md-6 portfolio-item Social-Media">
            <img src={require("../../assets/img/portfolio/page_22.JPG")} className="img-fluid" alt=""/>
            <div className="portfolio-info">
              <h4>Social-Media</h4>
              <p>Social-Media</p>
              
              
            </div>
          </div>


          <div className="col-lg-4 col-md-6 portfolio-item Gaming">
            <img src={require("../../assets/img/portfolio/page_23.JPG")} className="img-fluid" alt=""/>
            <div className="portfolio-info">
              <h4>Gaming</h4>
              <p>Gaming</p>
              
              
            </div>
          </div>


          <div className="col-lg-4 col-md-6 portfolio-item Social-Media">
            <img src={require("../../assets/img/portfolio/page_24.JPG")} className="img-fluid" alt=""/>
            <div className="portfolio-info">
              <h4>Social-Media</h4>
              <p>Social-Media</p>
              
              
            </div>
          </div>

          <div className="col-lg-4 col-md-6 portfolio-item Communication">
            <img src={require("../../assets/img/portfolio/page_25.JPG")} className="img-fluid" alt=""/>
            <div className="portfolio-info">
              <h4>Communication</h4>
              <p>Communication</p>
              
              
            </div>
          </div>

          <div className="col-lg-4 col-md-6 portfolio-item Communication">
            <img src={require("../../assets/img/portfolio/exotel.jpeg")} className="img-fluid" alt=""/>
            <div className="portfolio-info">
              <h4>Communication</h4>
              <p>Communication</p>
              
              
            </div>
          </div>

          <div className="col-lg-4 col-md-6 portfolio-item Financial-Services">
            <img src={require("../../assets/img/portfolio/page_26.JPG")} className="img-fluid" alt=""/>
            <div className="portfolio-info">
              <h4>Financial-Services</h4>
              <p>Financial-Services</p>
              
              
            </div>
          </div>

          <div className="col-lg-4 col-md-6 portfolio-item filter-web">
            <img src={require("../../assets/img/portfolio/page_27.JPG")} className="img-fluid" alt=""/>
            <div className="portfolio-info">
              <h4>Web 3</h4>
              <p>Web</p>
              
              
            </div>
          </div>

          <div className="col-lg-4 col-md-6 portfolio-item Retail">
            <img src={require("../../assets/img/portfolio/page_28.JPG")} className="img-fluid" alt=""/>
            <div className="portfolio-info">
              <h4>Retail</h4>
              <p>Retail</p>
              
              
            </div>
          </div>

          <div className="col-lg-4 col-md-6 portfolio-item Communication">
            <img src={require("../../assets/img/portfolio/page_29.JPG")} className="img-fluid" alt="" />
            <div className="portfolio-info">
              <h4>Communication</h4>
              <p>Communication</p>
              
              
            </div>
          </div>

          <div className="col-lg-4 col-md-6 portfolio-item Media-Entertainment">
            <img src={require("../../assets/img/portfolio/page_30.JPG")} className="img-fluid" alt=""/>
            <div className="portfolio-info">
              <h4>Media-Entertainment</h4>
              <p>Media-Entertainment</p>
              
              
            </div>
          </div>

          <div className="col-lg-4 col-md-6 portfolio-item Communication">
            <img src={require("../../assets/img/portfolio/page_31.JPG")} className="img-fluid" alt=""/>
            <div className="portfolio-info">
              <h4>Communication</h4>
              <p>Communication</p>
              
              
            </div>
          </div>


          <div className="col-lg-4 col-md-6 portfolio-item Healthcare">
            <img src={require("../../assets/img/portfolio/page_32.JPG")} className="img-fluid" alt=""/>
            <div className="portfolio-info">
              <h4>Healthcare</h4>
              <p>Healthcare</p>
              
              
            </div>
          </div>
          <div className="col-lg-4 col-md-6 portfolio-item Healthcare">
            <img src={require("../../assets/img/portfolio/mycarecrew.jpeg")} className="img-fluid" alt=""/>
            <div className="portfolio-info">
              <h4>Healthcare</h4>
              <p>Healthcare</p>
              
              
            </div>
          </div>



          <div className="col-lg-4 col-md-6 portfolio-item filter-web">
            <img src={require("../../assets/img/portfolio/page_33.JPG")} className="img-fluid" alt=""/>
            <div className="portfolio-info">
              <h4>Web 3</h4>
              <p>Web</p>
              
              
            </div>
          </div>

          <div className="col-lg-4 col-md-6 portfolio-item filter-web">
            <img src={require("../../assets/img/portfolio/page_34.JPG")} className="img-fluid" alt=""/>
            <div className="portfolio-info">
              <h4>Web 3</h4>
              <p>Web</p>
              
              
            </div>
          </div>

        </div>

      </div>
    </section>
  )
}

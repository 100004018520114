import React from 'react'
import mgImage from "../../assets/img/banner.png";
export default function Managedqa() {
  return (
    <section id="team" className="team section-bg section-bg-dark">
      <div className="container">

        <div className="section-title">
          <span>Managed QA</span>
          <h2>Managed QA</h2>
          
        </div>

        <div className="row">
          <div className="col-lg-6 pt-4 pt-lg-0 content">
            <p>Work with the largest network of independent professionals and get things done—from quick turnarounds to big transformations.</p>
            <ul>
              <li><i className="bi bi-check-circle"></i> Dedicated Team
              </li>
              <li><i className="bi bi-check-circle"></i> Flexibility in Time Zones</li>
              <li><i className="bi bi-check-circle"></i>Cost-effective Solutions</li>
              <li><i className="bi bi-check-circle"></i>Retention Policy</li>
              <li><i className="bi bi-check-circle"></i>Flexible Engagement Models</li>
              <li><i className="bi bi-check-circle"></i>On-Time Delivery</li>
              <li><i className="bi bi-check-circle"></i>Maintenance and Support</li>
              <li><i className="bi bi-check-circle"></i>Performance Management</li>
            </ul>  
          </div>
          <div className="col-lg-6 pt-4 pt-lg-0">
                <img src={mgImage} alt="" title=""/>
          </div>


        </div>

      </div>
    </section>
  )
}

import React from "react";
import Slider from "react-slick";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import meImage from "../../assets/img/tester imgs/img1.jpg";
import imImage from "../../assets/img/tester imgs/img2.jpg";
import alImage from "../../assets/img/tester imgs/img3.jpg";
import npImage from "../../assets/img/tester imgs/img5.jpg";
import pwImage from "../../assets/img/tester imgs/img6.jpg";
import hnImage from '../../assets/img/tester imgs/img4.jpg';
import skImage from "../../assets/img/tester imgs/img7.jpg";
export default function Services() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <section id="services" className="featured-services section-bg">
      <div className="container">
        <div className="section-title">
          <span>Testers By Skills</span>
          <h2>Testers By Skills</h2>
        </div>

        <div className="col-12 p-relative tester-review-slider">
        <Slider {...settings}>
           
        <div className="card">
                <div className="client-name row">
                  <div className="col-md-12">
                  <img src={meImage} alt="Minerva Cisneros" className="img 1" />
                  </div>
                  <div className="col-md-12 p-t10 text-center">
                    <p className="mrgn-0">
                      <b>Minerva Cisneros</b>
                    </p>
                    <p>
                      <b>QA Tester</b>
                    </p>
                  </div>
                  <div className="col-md-12 d-flex justify-content-center">
                    <div className="phoca-flag-flags us" title="United States"></div>
                  </div>
                  <div className="col-md-12 text-center">
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                  </div>
                 
                  

                 
                  <div className="col-md-12">
                    <b>Skills</b>: Security Testing, Usability Testing, Automation Testing, JMeter, Selenium

                  </div>
                </div>
              </div>
           
              <div className="card">
                <div className="client-name row">
                  <div className="col-md-12">
                  <img src={imImage} alt="Minerva Cisneros" className="img 1" />
                  </div>
                  <div className="col-md-12 p-t10 text-center">
                    <p className="mrgn-0">
                      <b>Ilknaz Mabasa</b>
                    </p>
                    <p>
                      <b>UX Tester</b>
                    </p>
                  </div>
                  <div className="col-md-12 d-flex justify-content-center">
                    <div className="phoca-flag-flags uk" title="United Kingdom"></div>
                  </div>
                  <div className="col-md-12 text-center">
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                  </div>
                 
                  

                 
                  <div className="col-md-12">
                    <b>Skills</b>: HealthCare, Meadia & Enteratinment, manual testing, Payment Testing, APi Testing

                  </div>
                </div>
              </div>
          
              <div className="card">
                <div className="client-name row">
                  <div className="col-md-12">
                  <img src={alImage} alt="Minerva Cisneros" className="img 1" />
                  </div>
                  <div className="col-md-12 p-t10 text-center">
                    <p className="mrgn-0">
                      <b>Antony Lobo</b>
                    </p>
                    <p>
                      <b>QA Architect</b>
                    </p>
                  </div>
                  <div className="col-md-12 d-flex justify-content-center">
                    <div className="phoca-flag-flags au" title="India"></div>
                  </div>
                  <div className="col-md-12 text-center">
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star "></span>
                  </div>
                 
                  

                 
                  <div className="col-md-12">
                    <b>Skills</b>: BFSI, Ecommerce, Automobile, Telecom, Manual Testing, Android
                    Testing...etc
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="client-name row">
                  <div className="col-md-12">
                  <img src={npImage} alt="Minerva Cisneros" className="img 1" />
                  </div>
                  <div className="col-md-12 p-t10 text-center">
                    <p className="mrgn-0">
                      <b>Narendra P R</b>
                    </p>
                    <p>
                      <b>Security Tester</b>
                    </p>
                  </div>
                  <div className="col-md-12 d-flex justify-content-center">
                    <div className="phoca-flag-flags in" title="India"></div>
                  </div>
                  <div className="col-md-12 text-center">
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star "></span>
                  </div>
                
                  

                 
                  <div className="col-md-12">
                    <b>Skills</b>: Manual Testing, Automation Testing, Security
                    Testing...etc
                  </div>
                </div>
              </div>
            

           
              <div className="card">
                <div className="client-name row">
                  <div className="col-md-12">
                  <img src={pwImage} alt="Minerva Cisneros" className="img 1" />
                  </div>
                  <div className="col-md-12 p-t10 text-center">
                    <p className="mrgn-0">
                      <b>Paweł Walenda</b>
                    </p>
                    <p>
                      <b>Localization Tester</b>
                    </p>
                  </div>
                  <div className="col-md-12 d-flex justify-content-center">
                    <div className="phoca-flag-flags pl" title="Poland"></div>
                  </div>
                  <div className="col-md-12 text-center">
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star s"></span>
                  </div>
                 
                 
                  <div className="col-md-12">
                    <b>Skills</b>: Manual Testing, Localization Testing,
                    Security Testing...etc
                  </div>
                </div>
              </div>
           

           
              <div className="card">
                <div className="client-name row">
                  <div className="col-md-12">
                  <img src={hnImage} alt="Minerva Cisneros" className="img 1" />
                  </div>
                  <div className="col-md-12 p-t10 text-center">
                    <p className="mrgn-0">
                      <b>Hrvoje Nikolic</b>
                    </p>
                    <p>
                      <b>QA Engineer</b>
                    </p>
                  </div>
                  <div className="col-md-12 d-flex justify-content-center">
                    <div className="phoca-flag-flags ct" title="Croatia"></div>
                  </div>
                  <div className="col-md-12 text-center">
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                  </div>
                 
                 

            
                  <div className="col-md-12">
                    <b>Skills</b>: Manual Testing, E Commerce Testing,
                    Automation Testing...etc
                  </div>
                </div>
              </div>
            

          
              <div className="card">
                <div className="client-name row">
                  <div className="col-md-12">
                  <img src={skImage} alt="Minerva Cisneros" className="img 1" />
                  </div>
                  <div className="col-md-12 p-t10 text-center">
                    <p className="mrgn-0">
                      <b>Shubham Kumar</b>
                    </p>
                    <p>
                      <b>Software Engineer</b>
                    </p>
                  </div>
                  <div className="col-md-12 d-flex justify-content-center">
                    <div className="phoca-flag-flags in" title="India"></div>
                  </div>
                  <div className="col-md-12 text-center">
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                  </div>
                  
                  <div className="col-md-12">
                    <b>Skills</b>: Manual Testing, Automation Testing, E
                    Commerce Testing...etc
                  </div>
                </div>
              </div>
    
         </Slider>
        </div>
      </div>
    </section>
  );
}

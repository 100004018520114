import React from 'react'
import Whatwetest from './Whatwetest'
import Portfolio from './Portfolio'
import CountSection from './CountSection'
import Managedqa from './Managedqa'
import Ourskill from './Ourskill'
import Location from './Location'
import Services from './Services'
export default function Home() {
  return (
    <>
    <Ourskill/>   
    <CountSection/>
    <Whatwetest/>
    <Location/>
    <Services/>
    <Portfolio/>
    <Managedqa/>
    </>
  )
}

import React from 'react';
import { Link } from 'react-router-dom';

export default function Whatwetest() {
  return (
    <section id="featured-services" className="featured-services section-bg">
      <div className="container">
        <div className="section-title">
          <span>What we test?</span>
          <h2>Our broad section of testing verticles</h2>
        </div>

        <div className="row">
          <div className="col-6 col-lg-4 col-md-6 mt-4 mt-lg-0">
            <div className="icon-box">
              <div className="icon">
                <Link to="/Mobile">
                  <i className="bi bi-phone-fill"></i>
                </Link>
              </div>
              <h4 className="title">
                <Link to="/Mobile">Mobile</Link>
              </h4>
            </div>
          </div>

          <div className="col-6 col-lg-4 col-md-6 mt-4 mt-lg-0">
            <div className="icon-box">
              <div className="icon">
                <Link to="/Website">
                  <i className="bi bi-browser-edge"></i>
                </Link>
              </div>
              <h4 className="title">
                <Link to="/Website">Websites, Web App's</Link>
              </h4>
            </div>
          </div>

          <div className="col-6 col-lg-4 col-md-6 mt-4 mt-lg-0">
            <div className="icon-box">
              <div className="icon">
                <Link to="/Desktop">
                  <i className="bi bi-pc-display-horizontal"></i>
                </Link>
              </div>
              <h4 className="title">
                <Link to="/Desktop">Desktop</Link>
              </h4>
            </div>
          </div>

          <div className="col-6 col-lg-4 col-md-6 mt-4 mt-lg-0">
            <div className="icon-box">
              <div className="icon">
                <Link to="/Iot">
                  <i className="bi bi-hdd-network-fill"></i>
                </Link>
              </div>
              <h4 className="title">
                <Link to="/Iot">IoT</Link>
              </h4>
            </div>
          </div>

          <div className="col-6 col-lg-4 col-md-6 mt-4 mt-lg-0">
            <div className="icon-box">
              <div className="icon">
                <Link to="/Arvr">
                  <i className="bi bi-headset-vr"></i>
                </Link>
              </div>
              <h4 className="title">
                <Link to="/Arvr">AR, VR</Link>
              </h4>
            </div>
          </div>

          <div className="col-6 col-lg-4 col-md-6 mt-4 mt-lg-0">
            <div className="icon-box">
              <div className="icon">
                <Link to="/ChatBots">
                  <i className="bi bi-chat-text"></i>
                </Link>
              </div>
              <h4 className="title">
                <Link to="/ChatBots">ChatBots</Link>
              </h4>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

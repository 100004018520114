import React from "react";
import { Link } from "react-router-dom";
export default function Client() {
  const showPassword = (event) => {
    let password = document.getElementById("password");
    if (password.type === "password") {
      password.type = "text";
    } else {
      password.type = "password";
    }
  };
  return (
    <section classNameNameName="inner-page section-bg">
      <div classNameNameName="container">
        <div classNameNameName="row">
          <div classNameNameName="tester-client col-md-12">
            <h2>Client Sign up</h2>
          </div>
        </div>
        <div classNameNameName="row tester-signUp">         

          <div classNameNameName="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch fields">
            <form method="post" role="form" classNameNameName="php-email-form">
              <div classNameNameName="row">
               

                <div classNameNameName="form-group col-md-12 mb-3">
                  <label htmlFor="organization">Organization</label>
                  <input
                    type="text"
                    name="organization"
                    classNameNameName="form-control"
                    id="organization"
                    required
                  />
                </div>
                

                <div classNameNameName="form-group col-md-12 mt-3 mb-3 mt-md-0 relative">
                  <label htmlFor="email">Email ID</label>
                  <input
                    type="email"
                    classNameNameName="form-control"
                    name="email"
                    id="email"
                    required
                  />
                 
                </div>

                <div classNameNameName="form-group col-md-6 mt-3 mb-3 mt-md-0 relative">
                  <label htmlFor="phone">Phone</label>
                  <input
                    type="number"
                    classNameNameName="form-control"
                    name="phone"
                    id="phone"
                    required
                  />
                
                </div>


                <div classNameNameName="form-group col-md-6 mt-3 mb-3 mt-md-0 relative">
                  <label htmlFor="location">Select location</label>
                  <input
                    type="text"
                    classNameNameName="form-control"
                    name="location"
                    id="location"
                    required
                  />
                 
                </div>

                <div classNameNameName="form-group col-md-6 mt-3 mb-3 mt-md-0 relative">
                  <label htmlFor="password">Create password</label>
                  <input
                    type="password"
                    classNameNameName="form-control"
                    name="password"
                    id="password"
                    required
                  />
                  <i
                    className="bi bi-eye-fill eye-icon"
                    onClick={(event) => showPassword(event)}
                  ></i>
                </div>

                <div classNameNameName="form-group col-md-6 mt-3 mb-3 mt-md-0 relative">
                  <label htmlFor="c-password">Confirm password</label>
                  <input
                    type="password"
                    classNameNameName="form-control"
                    name="c-password"
                    id="c-password"
                    required
                  />
                  
                </div>



                <div classNameNameName="col-lg-12 mt-5 mt-lg-0 d-flex align-items-stretch fields">
                  <button classNameNameName="login-btn">Submit</button>
                </div>
                <div classNameNameName="col-md-6 mt-3  d-flex align-items-stretch">
                  <Link to="/Login" classNameNameName="link-color">
                    Login
                  </Link>
                </div>

                <div classNameNameName="col-md-6 mt-3 d-flex align-items-stretch j-right ">
                  <Link to="/Forgotpassword" classNameNameName="link-color">
                    Forgot Password
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

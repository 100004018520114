import React from "react";
export default function Joinus() {
   

  return (
    <section className="inner-page hero-section-bg">
      <div className="container">
        <div className="row join-us">
          <div className="col-lg-12">
            <div className="section-title">
              <h1>Marketplace for Software Testing and Quality Assurance</h1>
            </div>
          </div>
          <div className="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch fields">
            <div className="">
              <p>
                Crowd4Test is one of the world's foremost organization which is
                adroit in delivering a transparent, exquisite, and
                cost-efficient testing service. We are upholding 5000+
                experienced and highly skilled freelance testers across 120+
                countries with more than 2000+ unique registered devices. We are
                providing 10+ different testing services at various pricing
                plans according to the budget. We have worked with 100+ clients
                and delivering quality reports/ results for the past 4 years
                until now.
              </p>
              <p>
                Crowd4Test is one of the world's foremost organization which is
                adroit in delivering a transparent, exquisite, and
                cost-efficient testing service. We are upholding 5000+
                experienced and highly skilled freelance testers across 120+
                countries with more than 2000+ unique registered devices. We are
                providing 10+ different testing services at various pricing
                plans according to the budget. We have worked with 100+ clients
                and delivering quality reports/ results for the past 4 years
                until now.
              </p>
              <p>
                Crowd4Test enables you to optimize your digital products and
                make it user-friendly. You can overcome all bugs, crashes, and
                performance-related issues with our Quality Assurance services.
                You can optimize the user experience, tackle usability issues,
                and many other obstacles in your product with the help of the
                unique crowd all around the globe in a shorter span of time
                which is not possible with your in-house team.
              </p>
            </div>
          </div>
          <div className="col-lg-5 mt-5 mt-lg-0 d-flex align-items-stretch fields formsfield">
            <form method="post" role="form" className="php-email-form">
              <div className="section-title">
                <h1>Contact Us</h1>
              </div>
              <div className="row">
                <div className="form-group col-md-6 mb-3">
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    id="name"
                    required
                  />
                </div>

                <div className="form-group col-md-6 mb-3">
                  <label htmlFor="email">Business Email</label>
                  <input
                    type="email"
                    name="name"
                    className="form-control"
                    id="email"
                    required
                  />
                </div>

                <div className="form-group col-md-12 mb-3">
                  <label htmlFor="org_name">Organisation</label>
                  <input
                    type="text"
                    name="org_name"
                    className="form-control"
                    id="org_name"
                    required
                  />
                </div>

                <div className="form-group col-md-6 mb-3">
                  <label htmlFor="mobile">Mobile</label>
                  <input
                    type="number"
                    name="mobile"
                    className="form-control"
                    id="mobile"
                    required
                  />
                </div>

                <div className="form-group col-md-6 mb-3">
                  <label htmlFor="location">Location</label>
                  <input
                    type="text"
                    name="location"
                    className="form-control"
                    id="location"
                    required
                  />
                </div>
                
              
                <div className="col-lg-12 mt-5 mt-lg-0 d-flex align-items-stretch fields">
                  <button className="login-btn">Send Details</button>
                </div>
                
              </div>
            </form>
          </div>
        </div>
      </div>

    
    </section>
  );
}

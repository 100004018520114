import React from "react";

export default function Desktop() {
  return (
    <>
      <section className="inner-page">
        <div className="inner-page-banner d-flex align-items-center justify-content-between">
          <img
            src={require("../../assets/img/wetest/desktop_app.png")}
            alt=""
            title=""
          />
        </div>
      </section>
      <section className="inner-page-content">
        <div className="container">
          <div className="section-title">
            <span>Desktop Application Testing</span>
            <h2>Desktop Application Testing</h2>
            <p>
              A desktop application is developed the same as other application
              that runs on personal computers and workstations, so when it comes
              to testing the desktop application you are focusing on a specific
              environment. You will test complete application broadly in
              categories like GUI, functionality, Load, and backend i.e Database
            </p>
          </div>
        </div>

        <div className="box">
          <div className="container">
            <h2 className="mb-lg-3 mt-lg-3">
            Major challenges faced in Desktop Application Testing
            </h2>
            <ul>
              <li>
                <p className="teel-text font-weight-bold">Graphical User Interface Testing</p>
                <p className="grey-text">
                The graphical user interface (GUI) is a part of user interface which lets users to interact with electronic devices through graphical icons and visual indicators such as secondary notation, instead of text-based user interfaces, typed command labels or text navigation. A GUI uses a combination of technologies and devices to provide a platform that users can interact with, for the tasks of gathering and producing information. Things to look after while testing GUI are
                <br />
                <ol>
                    <li>GUI elements size and color are been verified.</li>
                    <li>Assure that the GUI works according to the functionality of the app.</li>
                    <li>To check if the error messages are displayed correctly.</li>
                    <li>To verify whether the different sections on screen separated clearly.</li>
                    <li>Checking the readability of the fonts used.</li>
                    <li>Verifying the warning messages acceptability.</li>
                    <li>Checking the alignment and quality of pictures.</li>
                    <li>Checking the placement of GUI elements on different screen resolution.</li>
                </ol>
                </p>
              </li>
              <li>
                <p className="teel-text font-weight-bold">Compatibility Testing</p>
                <p className="grey-text">
                Compatibility testing is a piece of software non-functional testing which is managed on the application to ensure the compatibility of the application with the different computing environment.
                </p>
              </li>
              <ul>
                    <li><span className="teel-text">Hardware:</span>To check the compatibility of the software in different hardware configuration devices.</li>
                    <li><span className="teel-text">Operating System:</span>To check the compatibility of the software in different operating systems like Windows, Unix, Mac OS, etc.</li>
                    <li><span className="teel-text">Software:</span>To check software developed to be compatible with other software. For example, to check whether MS Word application is compatible with other software like MS Outlook, MS Excel, VBA, etc.</li>
                    <li><span className="teel-text">Network:</span>Calculating the performance of a system in a network with varying parameters such as Bandwidth, Operating speed, and Capacity. It also checks the registration in different networks with all parameters mentioned earlier.</li>
                    <li><span className="teel-text">Browser:</span>To check the compatibility of the website with different browsers like Firefox, Google Chrome, Internet Explorer, etc.</li>
                    <li><span className="teel-text">Devices:</span>To check the compatibility of the software with different devices like USB port Devices, Printers and Scanners, Other media devices and Bluetooth.</li>
                    <li><span className="teel-text">Mobile:</span>To check the compatibility of the software with mobile platforms like Android, iOS, etc.</li>
                    <li><span className="teel-text">Software:</span>Versions To check whether your software is compatible with different versions of the software. For example to check Microsoft Word to be compatible with Windows 7, Windows 7 SP1, Windows 7 SP2, Windows 7 SP3.</li>
                </ul>
              <li>
                <p className="teel-text font-weight-bold"><br />Functional Testing</p>
                <p className="grey-text">
                Functional Testing is a type of testing practiced to verify that each function, feature, and module of the application is functioning as per the given requirements by the client. Each performance of the application has to be tested by providing given set of inputs (positive, negative and edge values) in order to know the actual behavior of the application and thereafter comparing with the expected results as per the given specifications. Functional testing involves the following process
                </p>
                <ol>
                    <li>To identify the functions that software is expected to perform.</li>
                    <li>Based on the function’s specifications data has to be created.</li>
                    <li>Determine the output based on the function’s specifications.</li>
                    <li>Execute the test case.</li>
                    <li>Compare the actual and expected results.</li>
                </ol>
              </li>
              <li>
                <p className="teel-text font-weight-bold"><br />Performance Testing</p>
                <p className="grey-text">
                In software quality assurance, performance testing is a method which is performed to determine how a system performs in terms of responsiveness and stability under a particular workload. Things to be taken care while testing GUI are
                </p>
                <ul>
                    <li><span className="teel-text">Throughput:</span>The number of many units of information that a system processes within the specified time.</li>
                    <li><span className="teel-text">Memory:</span>The amount of free storage space for a processor or workload.</li>
                    <li><span className="teel-text">Response Time:</span>The amount of time taken between a user inquire for request and the start of a system's response to that request.</li>
                    <li><span className="teel-text">Bandwidth:</span>The amount of data per second that can move between workloads, usually across a network.</li>
                    <li><span className="teel-text">CPU interrupts per second:</span>The total number of hardware interrupts a process receives per seconds.</li>
                </ul>
              </li>
              
            </ul>
           
          </div>
        </div>
      </section>
    </>
  );
}

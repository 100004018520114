import React, { useEffect } from "react";
import aboutus from "../../assets/img/services/aboutus.png";
import GlobalMap from "../../assets/img/services/Global_Map.png";
import airmeet from "../../assets/img/public_assets/airmeet.svg"
import PureCounter from "@srexi/purecounterjs";
import '../about/about.css';
export default function Aboutus() {

  useEffect(() => {
    new PureCounter({
      selector: ".purecounter",
      start: 0,
      end: 100,
      duration: 2,
      delay: 10,
      once: true,
      repeat: false,
      decimals: 0,
      legacy: true,
      filesizing: false,
      currency: false,
      separator: false,
    });
  }, []);

  return (
    <>
      <section className="inner-page about" id="about">
        <div className="inner-page-banner d-flex align-items-center justify-content-between">
          <img src={aboutus} alt="" title="" />
        </div>
      </section>
      <section className="aboutus about" id="about">
        <div className="container">
          <div className="row">
            <div className="col-lg-9 pt-4 pt-lg-0 content">
              <h3 className="mb-lg-4">About Us</h3>
              <ul>
                <li>
                  <i className="bi bi-check-circle"></i> Crowd4Test is one of
                  the world’s foremost company which is adroit in delivering a
                  transparent, exquisite, and cost-efficient testing in a
                  user-centric world. Crowd4Test gives you the ability to
                  radically expand your in-house testing team for a fixed short
                  duration of time without investing in headcount and hiring.
                </li>
                <li>
                  <i className="bi bi-check-circle"></i>Crowd4Test was founded
                  by Ranganadh Damera (Founder/ CEO) in the year 2015 at
                  Bangalore. In his career journey, he has worked with major IT
                  giants like Samsung, Sasken, Huawei, Accenture with a solid
                  experience of 16 years in the testing industry. The immense
                  passion and drive in him towards entrepreneurship since the
                  very budding years has led to the foundation of Crowd4Test.
                </li>
              </ul>
            </div>

            <div className="col-lg-3 pos-realtive align-center"></div>

          </div>
        </div>
      </section>

      <section id="services" className="counts section-bg">
        <div className="container">
          <div className="section-title">
            <span>Achievements</span>
            <h2>Achievements</h2>
          </div>

          <div className="row counters">
            <div className="col-lg-3 col-6 text-center">
              <i className="bi bi-people"></i>

              <span
                data-purecounter-start="0"
                data-purecounter-end="6000"
                data-purecounter-duration="1"
                className="purecounter"
              ></span>
              <p>Testers</p>
            </div>

            <div className="col-lg-3 col-6 text-center">
              <i className="bi bi-collection"></i>
              <span
                data-purecounter-start="0"
                data-purecounter-end="2000"
                data-purecounter-duration="1"
                className="purecounter"
              ></span>
              <p>Tests Completed</p>
            </div>

            <div className="col-lg-3 col-6 text-center">
              <i className="bi bi-binoculars"></i>
              <span
                data-purecounter-start="0"
                data-purecounter-end="2000"
                data-purecounter-duration="1"
                className="purecounter"
              ></span>
              <p>Devices</p>
            </div>

            <div className="col-lg-3 col-6 text-center">
              <i className="bi bi-pin-map"></i>
              <span
                data-purecounter-start="0"
                data-purecounter-end="120"
                data-purecounter-duration="1"
                className="purecounter"
              ></span>
              <p>Global Presence</p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="section-title">
            <span>Global Presence</span>
            <h2>Global Presence</h2>
          </div>
          <div className="row">
            <div className="col-lg-12 col-12">
              <img width={"100%"} src={GlobalMap} alt="" title="" />
            </div>
          </div>
        </div>
      </section>

      <section className="section-bg clients">
        <div className="container">
          <div className="section-title">
            <span>Clients</span>
            <h2>Clients</h2>
          </div>
          <center>
                        <div className="row lgimg my-5">
                                <div className="col col-sm-2"><img src={airmeet} alt="Crowd4Test - clients"/></div>
                                <div className="col col-sm-2"><img src={require("../../assets/img/public_assets/apalya.png")} alt="Crowd4Test - clients"/></div>
                                <div className="col col-sm-2"><img src={require("../../assets/img/public_assets/charmboard.png")} alt="Crowd4Test - clients"/></div>
                                <div className="col col-sm-2"><img src={require("../../assets/img/public_assets/confirmtkt.png")} alt="Crowd4Test - clients"/></div>
                                <div className="col col-sm-2"><img src={require("../../assets/img/public_assets/digitalturbine.png")} alt="Crowd4Test - clients"/></div>
                                <div className="col col-sm-2"><img src={require("../../assets/img/public_assets/doodhwala.png")} alt="Crowd4Test - clients"/></div>
                        </div>
                        <div className="row lgimg my-5">
                                <div className="col col-sm-2"><img src={require("../../assets/img/public_assets/finalrental.png")} alt="Crowd4Test - clients"/></div>
                                <div className="col col-sm-2"><img src={require("../../assets/img/public_assets/firstscreen.png")} alt="Crowd4Test - clients"/></div>
                                <div className="col col-sm-2"><img src={require("../../assets/img/public_assets/fiturtle.png")} alt="Crowd4Test - clients"/></div>
                                <div className="col col-sm-2"><img src={require("../../assets/img/public_assets/golbol.jpg")} alt="Crowd4Test - clients"/></div>
                                <div className="col col-sm-2"><img src={require("../../assets/img/public_assets/hexagon.png")} alt="Crowd4Test - clients"/></div>
                                <div className="col col-sm-2"><img src={require("../../assets/img/public_assets/heyyou.png")} alt="Crowd4Test - clients"/></div>
                        </div>
                        <div className="row lgimg my-5">
                                <div className="col col-sm-2"><img src={require("../../assets/img/public_assets/hqdemand.jpg")} alt="Crowd4Test - clients"/></div>
                                <div className="col col-sm-2"><img src={require("../../assets/img/public_assets/hungurybells.png")} alt="Crowd4Test - clients"/></div>
                                <div className="col col-sm-2"><img src={require("../../assets/img/public_assets/indiawin.png")} alt="Crowd4Test - clients"/></div>
                                <div className="col col-sm-2"><img src={require("../../assets/img/public_assets/isos.png")} alt="Crowd4Test - clients"/></div>
                                <div className="col col-sm-2"><img src={require("../../assets/img/public_assets/jaiho.png")} alt="Crowd4Test - clients"/></div>
                                <div className="col col-sm-2"><img src={require("../../assets/img/public_assets/kaha.png")} alt="Crowd4Test - clients"/></div>
                        </div>
                        <div className="row lgimg my-5">
                                <div className="col col-sm-2"><img src={require("../../assets/img/public_assets/khichadi.png")} alt="Crowd4Test - clients"/></div>
                                <div className="col col-sm-2"><img src={require("../../assets/img/public_assets/kruzr.png")} alt="Crowd4Test - clients"/></div>
                                <div className="col col-sm-2"><img src={require("../../assets/img/public_assets/m800.png")} alt="Crowd4Test - clients"/></div>
                                <div className="col col-sm-2"><img src={require("../../assets/img/public_assets/makrick.png")} alt="Crowd4Test - clients"/></div>
                                <div className="col col-sm-2"><img src={require("../../assets/img/public_assets/mentorcloud.png")} alt="Crowd4Test - clients"/></div>
                                <div className="col col-sm-2"><img src={require("../../assets/img/public_assets/messageloud.png")} alt="Crowd4Test - clients"/></div>
                        </div>
                        <div className="row lgimg my-5">
                                <div className="col col-sm-2"><img src={require("../../assets/img/public_assets/mycity4kids.jpg")} alt="Crowd4Test - clients"/></div>
                                <div className="col col-sm-2"><img src={require("../../assets/img/public_assets/newsdistill.jpg")} alt="Crowd4Test - clients"/></div>
                                <div className="col col-sm-2"><img src={require("../../assets/img/public_assets/ohai.png")} alt="Crowd4Test - clients"/></div>
                                <div className="col col-sm-2"><img src={require("../../assets/img/public_assets/pcloudy.png")} alt="Crowd4Test - clients"/></div>
                                <div className="col col-sm-2"><img src={require("../../assets/img/public_assets/renepay.png")} alt="Crowd4Test - clients"/></div>
                                <div className="col col-sm-2"><img src={require("../../assets/img/public_assets/rewardz.png")} alt="Crowd4Test - clients"/></div>
                        </div>
                        <div className="row lgimg my-5">
                                <div className="col col-sm-2"><img src={require("../../assets/img/public_assets/rydo.png")} alt="Crowd4Test - clients"/></div>
                                <div className="col col-sm-2"><img src={require("../../assets/img/public_assets/savaari.png")} alt="Crowd4Test - clients"/></div>
                                <div className="col col-sm-2"><img src={require("../../assets/img/public_assets/shopitsoon.png")} alt="Crowd4Test - clients"/></div>
                                <div className="col col-sm-2"><img src={require("../../assets/img/public_assets/smacar.png")} alt="Crowd4Test - clients"/></div>
                                <div className="col col-sm-2"><img src={require("../../assets/img/public_assets/trustingsocial.png")} alt="Crowd4Test - clients"/></div>
                                <div className="col col-sm-2"><img src={require("../../assets/img/public_assets/ulatus.png")} alt="Crowd4Test - clients"/></div>
                        </div>
                        <div className="row lgimg my-5">
                                <div className="col col-sm-2"><img src={require("../../assets/img/public_assets/vebbler.png")} alt="Crowd4Test - clients"/></div>
                                <div className="col col-sm-2"><img src={require("../../assets/img/public_assets/voyageup.png")} alt="Crowd4Test - clients"/></div>
                                <div className="col col-sm-2"><img src={require("../../assets/img/public_assets/wealthapp.png")} alt="Crowd4Test - clients"/></div>
                                <div className="col col-sm-2"><img src={require("../../assets/img/public_assets/wish.png")} alt="Crowd4Test - clients"/></div>
                                <div className="col col-sm-2"><img src={require("../../assets/img/public_assets/familyhawk.jpg")} alt="Crowd4Test - clients"/></div>
                                <div className="col col-sm-2"><img src={require("../../assets/img/public_assets/zoopon.jpg")} alt="Crowd4Test - clients"/></div>
                        </div>
                </center>
        </div>
      </section>

 
    </>
  );
}

import React from "react";
import services from "../../assets/img/gif/localization.gif";
export default function Localization() {
  return (
    <>
      <section className="inner-page">
        <div className="inner-page-banner d-flex align-items-center justify-content-between">
          <img src={services} alt="" title="" />
        </div>
      </section>
      <section className="inner-page-content">
        <div className="container">
          <div className="section-title">
            <span>Localization Testing</span>
            <h2>Localization Testing</h2>
            <p>
             Localization testing is the process of evaluating 
             software or applications to ensure they are adapted 
             and function correctly for specific target markets 
             and cultures. It involves verifying language translations,
             date and time formats, currency symbols, cultural nuances, 
             and other region-specific elements to provide a seamless 
             user experience for diverse international audiences.
            </p>
          </div>
        </div>

        <div className="box">
          <div className="container">
            <p> 
                
                In today's globalized digital landscape, 
                various types of Localization testing are
                crucial to ensuring software products resonate 
                with diverse audiences.  
            </p>
            <h2 className="mb-lg-3 mt-lg-3">
              Some prominent types include:
            </h2>
            <ul>
              <li>
                <p className="teel-text font-weight-bold">
                  Language Testing:
                </p>
                <p className="black-text">
                 erifying accurate translation 
                 of user interface elements,
                 messages, and content.
                </p>
              </li>
              <li>
                <p className="teel-text font-weight-bold">Date and Time Format Testing: </p>
                <p className="black-text">
                  Ensuring date, time, and calendar
                  formats align with the target locale.
                </p>
              </li>
              <li>
                <p className="teel-text font-weight-bold">Regional Settings Testing: </p>
                <p className="black-text">
                 Testing based on region-specific settings
                 like measurement units, address formats,
                 and phone number conventions.
                </p>
              </li>
              <li>
                <p className="teel-text font-weight-bold">User Experience Testing: </p>
                <p className="black-text">
                 Evaluating user experience in
                 the context of cultural norms 
                 and expectations.
                </p>
              </li>

              <li>
                <p className="teel-text font-weight-bold">Accessibility in Localization: </p>
                <p className="black-text">
                 Ensuring that localized content is
                 accessible to users with disabilities.
                </p>
              </li>

              <li>
                <p className="teel-text font-weight-bold">Functionality Testing in Locale: </p>
                <p className="black-text">
                Testing application features specific 
                to the target locale or region.
                </p>
              </li>

              <li>
                <p className="teel-text font-weight-bold">Localization Testing in Different Environments: </p>
                <p className="black-text">
                 Testing in various platforms, devices,
                 and environments used by the target 
                 audience.
                </p>
              </li>

              <li>
                <p className="teel-text font-weight-bold">Time Zone Testing: </p>
                <p className="black-text">
                 Validating correct time zone
                 adjustments and daylight saving 
                 time transitions.
                </p>
              </li>

              <li>
                <p className="teel-text font-weight-bold">Geolocation Testing: </p>
                <p className="black-text"> 
                 Verifying that location-based features
                 function accurately based on the target 
                 region.
                </p>
              </li>

              <li>
                <p className="teel-text font-weight-bold">User Acceptance Testing (UAT): </p>
                <p className="black-text">
                 Engaging end-users from the target 
                 region to validate that the localized 
                 product meets their expectations.
                </p>
              </li>

            </ul>


            <p className="teel-text font-weight-bold">Testing approach for Localization :</p>
            <p className="black-text">
               Adaptively test software for Localization by translating 
               content, verifying cultural alignment, and validating
               functionality to ensure a seamless user experience across 
               diverse global markets.  
            </p>  
         </div>

        </div>
      </section>
    </>
  );
}

import React, { useEffect } from "react";
import webinarBanner from "../../assets/img/services/webinar_banner.svg";

import GlobalMap from "../../assets/img/services/Global_Map.png";
import PureCounter from "@srexi/purecounterjs";

export default function Webinar() {
  useEffect(() => {
    new PureCounter({
      selector: ".purecounter",
      start: 0,
      end: 100,
      duration: 2,
      delay: 10,
      once: true,
      repeat: false,
      decimals: 0,
      legacy: true,
      filesizing: false,
      currency: false,
      separator: false,
    });
  }, []);
  return (
    <>
      <section className="inner-page about" id="about">
        <div className="inner-page-banner d-flex align-items-center justify-content-between">
          <img src={webinarBanner} alt="" title="" />
        </div>
      </section>
      <section className="aboutus about" id="about">
        <div className="container">
          <div className="row">
            <div className="col-lg-9 pt-4 pt-lg-0 content">
              <h3 className="mb-lg-4">About The Webinar</h3>
              <ul>
                <li>
                  <i className="bi bi-check-circle"></i> Crowd4Test is one of
                  Crowdsourced testing is an emerging trend in software testing
                  which exploits the benefits, effectiveness, and efficiency of
                  crowdsourcing and the cloud platform. It differs from
                  traditional testing methods in that the testing is carried out
                  by a number of different testers from different places, and
                  not by hired consultants and professionals. The software /
                  Application is put to test under diverse realistic platforms
                  which makes it more reliable, cost-effective, fast, and
                  bug-free.In addition, crowdsource testing allows for remote
                  usability testing because specific target groups can be
                  recruited through the crowd.
                </li>
                <li>
                  <i className="bi bi-check-circle"></i>Crowd4Test was founded
                  This method of testing is considered when the software is more
                  user-centric: i.e., software whose success is determined by
                  its user feedback and which has a diverse user space. It is
                  frequently implemented with gaming, mobile applications, when
                  experts who may be difficult to find in one place are required
                  for specific testing, or when the company lacks the resources
                  or time to carry out the testing internally.
                </li>
              </ul>
            </div>

            <div className="col-lg-3 pos-realtive align-center"></div>
          </div>
        </div>
      </section>

      <section className="for-client">
        <div className="box">
          <div className="container">
            <h2 className="mb-lg-3 mt-lg-3">
            Why Should I attend this webinar?
            </h2>
            <ul>
              <li>
                <p className="grey-text">
                Better understanding of problems & process gaps.
                </p>
              </li>
              <li>
                <p className="grey-text">
                Effectively and efficiently deliver project results.
                </p>
              </li>
              <li>
                <p className="grey-text">
                Minimize cost while maximize project deliverables.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
}

import React, { useEffect } from 'react'
import PureCounter from '@srexi/purecounterjs';

export default function CountSection() {
    

    useEffect(() => {
        new PureCounter({
            selector: '.purecounter',
            start: 0, 
            end: 100,
            duration: 2,
            delay: 10,
            once: true,
            repeat: false,
            decimals: 0,
            legacy: true,
            filesizing: false,
            currency: false,
            separator: false,
        });

     }, []);
  return (
    <section id="counts" className="counts">
      <div className="container">
        <div className="section-title">
          <span>Our Statistics</span>
           <h2>Our Numbers Speak for Themselves</h2>
        </div>       
        <div className="row counters">

          <div className="col-lg-4 col-md-6 count-box">
            <i className="bi bi-people-fill"></i>   
      
            <span data-purecounter-start="0" data-purecounter-end="6000" data-purecounter-duration="1" className="purecounter"></span>
            <p>Testers</p>
          </div>

          <div className="col-lg-4 col-md-6 count-box">
            <i className="bi bi-collection-fill"></i>
            <span data-purecounter-start="0" data-purecounter-end="2000" data-purecounter-duration="1" className="purecounter"></span>
            <p>Tests Completed</p>
          </div>

          <div className="col-lg-4 col-md-6 count-box">
            <i className="bi bi-binoculars-fill"></i>
            <span data-purecounter-start="0" data-purecounter-end="2000" data-purecounter-duration="1" className="purecounter"></span>
            <p>Devices</p>
          </div>

          <div className="col-lg-4 col-md-6 count-box">
            <i className="bi bi-pin-map-fill"></i>
            <span data-purecounter-start="0" data-purecounter-end="120" data-purecounter-duration="1" className="purecounter"></span>
            <p>Global Presence</p>
          </div>

          <div className="col-lg-4 col-md-6 count-box">
            <i className="bi bi-1-square-fill"></i>
            <br />
            <span data-purecounter-start="0" data-purecounter-end="1" data-purecounter-duration="1" className="pureconter"></span>
            <br />
            <p>#1 Crowd Testing Platform </p>     
          </div>

          <div className="col-lg-4 col-md-6 count-box">
            <i className="bi bi-6-square-fill"></i>
            <br />
            <span data-purecounter-start="0" data-purecounter-end="6" data-purecounter-duration="1" className="pureconter"></span>
            <br />
            <p>Years in Business</p>       
          </div>


        </div>

      </div>
    </section>
  )
}

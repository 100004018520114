import React from "react";
import faqBanner from "../../assets/img/services/faq_banner.svg";
export default function Faq() {
  return (
    <>
      <section className="inner-page">
        <div className="inner-page-banner d-flex align-items-center justify-content-between">
          <img src={faqBanner} alt="" title="" />
        </div>
      </section>
      <section className="inner-page-content">
        <div className="container">
          <div className="section-title">
            <span>FAQ for Testers</span>
            <h2>FAQ for Testers</h2>
          </div>
        </div>
        <div className="box">
          <div className="container">
            <h2 className="mb-lg-3 mt-lg-3">What is crowdsourced testing?</h2>
            <ul>
              <li>
                <p className="grey-text">
                  Crowdsourced Testing consists of delegating software testing
                  tasks to a community of expert quality assurance testers
                  through an online platform. It is quickly emerging as the most
                  efficient way to streamline interactive software products and
                  improve the quality of the web and software products published
                  by developers. This method of testing is considered when the
                  software is more user-centric i.e., software whose success is
                  determined by its user feedback and which has a diverse user
                  space.
                </p>
              </li>
            </ul>

            <h2 className="mb-lg-3 mt-lg-3">Who is a freelancer?</h2>
            <ul>
              <li>
                <p className="grey-text">
                  A freelancer or freelance worker is a term commonly used for a
                  person who is self-employed and they don’t have any long term
                  commitment with that organization.
                </p>
              </li>
            </ul>

            <h2 className="mb-lg-3 mt-lg-3">
              What is the advantage of being a freelance tester?
            </h2>
            <ul>
              <li>
                <p className="grey-text">
                  Opportunity to interact directly with the client and fulfill
                  the requirements. You’ll be seeing rapid growth in your career
                  under a short span of time. Test your skills and develop your
                  experience and confidence level in the field of testing. You
                  can easily allocate time for your personal and professional
                  life as being a freelance tester.
                </p>
              </li>
            </ul>

            <h2 className="mb-lg-3 mt-lg-3">
              Can I work as a part-time tester?
            </h2>
            <ul>
              <li>
                <p className="grey-text">
                  Yes, Crowd4Test gives you an opportunity to work on an hourly,
                  part-time, and full-time based upon your convenience.
                </p>
              </li>
            </ul>

            <h2 className="mb-lg-3 mt-lg-3">
              How much experience do I need to be a freelance tester?
            </h2>
            <ul>
              <li>
                <p className="grey-text">
                  Crowd4Test owns many projects that will be suitable for all
                  the skill set ranging from beginner to experienced.
                </p>
              </li>
            </ul>

            <h2 className="mb-lg-3 mt-lg-3">
              How much do I earn as a freelance tester?
            </h2>
            <ul>
              <li>
                <p className="grey-text">
                  The pay scale for the freelance testers depends on various
                  factors such as time spent, the difficulty of the task, the
                  number of valid bugs found in the project.
                </p>
              </li>
            </ul>

            <h2 className="mb-lg-3 mt-lg-3">
              When will I get the money for a project?
            </h2>
            <ul>
              <li>
                <p className="grey-text">
                  The amount for a project will be credited to your crowd4test
                  wallet once it has been accomplished. Testers can release the
                  amount to your bank account (India Testers) and PayPal
                  (International Testers)
                </p>
              </li>
            </ul>

            <h2 className="mb-lg-3 mt-lg-3">
              When will I be assigned with a project once I registered?
            </h2>
            <ul>
              <li>
                <p className="grey-text">
                  The project will be assigned based on 2 factors one is the
                  availability of the project and the other is based upon the
                  skill & experience of the tester.
                </p>
              </li>
            </ul>

            <h2 className="mb-lg-3 mt-lg-3">
              What are the data will be collected and how secured will be the
              data?
            </h2>
            <ul>
              <li>
                <p className="grey-text">
                  Crowd4Test will collect information such as personal details,
                  skills, experience, location, devices, etc. The data which is
                  collected by us will be safe and secure and will not be leaked
                  at any point of time.
                </p>
              </li>
            </ul>

            <h2 className="mb-lg-3 mt-lg-3">
              Is there any option to delete my account from crowd4test
              permanently?
            </h2>
            <ul>
              <li>
                <p className="grey-text">
                  Yes, you can delete your account permanently from crowd4test
                  at any point of time when you wish to leave.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section className="inner-page-content for-client">
        <div className="container">
          <div className="section-title">
            <span>FAQ for Clients</span>
            <h2>FAQ for Clients</h2>
          </div>
        </div>
        <div className="box">
          <div className="container">
            <h2 className="mb-lg-3 mt-lg-3">
              What is the role of the client in crowd4test?
            </h2>
            <ul>
              <li>
                <p className="grey-text">
                  The clients are the key success of the crowd4test and we are
                  here functioning for the clients (business owners & app
                  developers). As a client, you can test your app using our
                  platform using services such as private, DIY, and managed.
                </p>
              </li>
            </ul>
            <h2 className="mb-lg-3 mt-lg-3">
              How can I become a client of crowd4test?
            </h2>
            <ul>
              <li>
                <p className="grey-text">
                  If you are owing a business and have your own website or/and
                  app the your at the right platform. You can register with us
                  as client here to test your app or/and website.
                </p>
              </li>
            </ul>

            <h2 className="mb-lg-3 mt-lg-3">
              What are the types of services offered by crowd4test?
            </h2>
            <ul>
              <li>
                <p className="grey-text">
                  Crowd4Test offers 3 types of service such as private, DIY, and
                  managed. In private, you will be acting as test managers and
                  you have to assign with your own testers. In DIY, the tester
                  will be yourself and you should have your own tester and you
                  have an option to pick some testers from crowd4test also. In
                  Managed, everything will be handled by the crowd4test and the
                  final report will be submitted to you.
                </p>
              </li>
            </ul>

            <h2 className="mb-lg-3 mt-lg-3">
              What is the pricing of all the services?
            </h2>
            <ul>
              <li>
                <p className="grey-text">
                  The pricing (Private, DIY, and Managed) offered by the
                  crowd4test will be different from each other. Get more
                  information about pricing{" "}
                  <a href="#/pricing" className="teel-text">
                    here
                  </a>
                  .
                </p>
              </li>
            </ul>

            <h2 className="mb-lg-3 mt-lg-3">
              Will my app be tested with real-time devices and users?
            </h2>
            <ul>
              <li>
                <p className="grey-text">
                  Yes, your application will be assigned and tested with more
                  than 5000 testers located all over the world and 1500
                  real-time devices which the testers have registered with us.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
}

import React from "react";
import GlobalMap from "../../assets/img/gif/tester by location.gif";
export default function Location() {
  return (
    <section className="section-bg section-bg-dark">
      <div className="container">
        <div className="section-title">
          <span>Tester By Location</span>
          <h2>Tester By Locations</h2>
          <div className="row">
            <div className="col">
              <img src={GlobalMap} className="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

import React from "react";
import { Link } from "react-router-dom";
export default function Tester() {
  const showPassword = (event) => {
    let password = document.getElementById("password");
    if (password.type === "password") {
      password.type = "text";
    } else {
      password.type = "password";
    }
  };
  return (
    <section className="inner-page section-bg">
      <div className="container">
        <div className="row">
          <div className="tester-client col-md-12">
            <h2>Tester Sign up</h2>
          </div>
        </div>
        <div className="row tester-signUp">         

          <div className="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch fields">
            <form method="post" role="form" className="php-email-form">
              <div className="row">
                <div className="form-group col-md-12 mb-3">
                  <label htmlFor="profession">Profession</label>
                  <select
                    className="form-control ng-pristine ng-valid ng-empty ng-touched"
                    name="profession"
                    id="profession"
                    ng-model="profession"
                  >
                    <option value="" selected="selected">
                      I am a
                    </option>
                    <option value="Sofware Tester">Software Tester</option>
                    <option value="IT Professional">IT Professional</option>
                    <option value="Other Professional">
                      Other Professional
                    </option>
                    <option value="Home Maker">Home Maker</option>
                    <option value="Student">Student</option>
                  </select>
                </div>

                <div className="form-group col-md-6 mb-3">
                  <label htmlFor="fname">First name</label>
                  <input
                    type="text"
                    name="fname"
                    className="form-control"
                    id="fname"
                    required
                  />
                </div>
                <div className="form-group col-md-6 mt-3 mb-3 mt-md-0 relative">
                  <label htmlFor="lname">Last name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="lname"
                    id="lname"
                    required
                  />
                 
                </div>

                <div className="form-group col-md-12 mt-3 mb-3 mt-md-0 relative">
                  <label htmlFor="email">Email ID</label>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    id="email"
                    required
                  />
                 
                </div>

                <div className="form-group col-md-6 mt-3 mb-3 mt-md-0 relative">
                  <label htmlFor="phone">Phone</label>
                  <input
                    type="number"
                    className="form-control"
                    name="phone"
                    id="phone"
                    required
                  />
                
                </div>


                <div className="form-group col-md-6 mt-3 mb-3 mt-md-0 relative">
                  <label htmlFor="location">ocation</label>
                  <input
                    type="text"
                    className="form-control"
                    name="location"
                    id="location"
                    required
                  />
                 
                </div>

                <div className="form-group col-md-6 mt-3 mb-3 mt-md-0 relative">
                  <label htmlFor="password">Create password</label>
                  <input
                    type="password"
                    className="form-control"
                    name="password"
                    id="password"
                    required
                  />
                  <i
                    className="bi bi-eye-fill eye-icon"
                    onClick={(event) => showPassword(event)}
                  ></i>
                </div>

                <div className="form-group col-md-6 mt-3 mb-3 mt-md-0 relative">
                  <label htmlFor="c-password">Confirm password</label>
                  <input
                    type="password"
                    className="form-control"
                    name="c-password"
                    id="c-password"
                    required
                  />
                  
                </div>



                <div className="col-lg-12 mt-5 mt-lg-0 d-flex align-items-stretch fields">
                  <button className="login-btn">Submit</button>
                </div>
                <div className="col-md-6 mt-3  d-flex align-items-stretch">
                  <Link to="/Login" className="link-color">
                    Login
                  </Link>
                </div>

                <div className="col-md-6 mt-3 d-flex align-items-stretch j-right ">
                  <Link to="/Forgotpassword" className="link-color">
                    Forgot Password
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}
